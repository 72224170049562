import { Component, OnInit, SecurityContext, ViewChild, ElementRef, NgZone, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogConfig, MatDialog } from '@angular/material';
import * as moment from 'moment';

import { environment } from '../../environments/environment';
import { Consumer, Booking, BookingOption, LastBooking } from '../models';
import { DataService } from '../data.service';
import { ErrorMessageDialogComponent } from '../dialogs/error-message/error-message.component';
import { ServerTimeService } from '../server-time/server-time.service';
import { WindowRef } from '../window.service';

@Component({
  templateUrl: './book.component.html'
})
export class BookComponent implements OnInit {
  consumer: Consumer;
  booking: Booking;

  loading = false;
  error_msg: string;
  success_msg: string;

  constructor(
    private dataService: DataService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private zone: NgZone, 
    private renderer: Renderer2,
    private serverTimeService: ServerTimeService,
    private winRef: WindowRef
  ) {
    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        const displayText = (this.countDown > 0)?(' (' + (--this.countDown) + ')'):'';
        if (this.refreshin)
          this.renderer.setProperty(this.refreshin.nativeElement, 'textContent', displayText);
        if (this.refreshin1)
          this.renderer.setProperty(this.refreshin1.nativeElement, 'textContent', displayText);
      }, 1000);
    });
  }

  ngOnInit() {
    this.consumer = this.dataService.getCurrentConsumer();
    this.booking = this.dataService.getBook();

    if (this.booking) {
      this.populateBookingOptions(this.booking);
    }
  }

  populateBookingOptions(booking: Booking) {
    if (booking.server_time) {
      this.serverTimeService.updateNewTime(moment(booking.server_time).toDate());
    }

    this.booking = {
      server_time: moment(booking.server_time),
      last_booking: null, 
      options: null 
    };

    if (booking && booking.last_booking) {
      this.setLastBooking(booking.last_booking);
    }

    if (booking && booking.options) {
      this.setBookingOptions(booking.options);
    }
  }

  refreshBookingOptions() {
    this.loading = true;
    this.dataService.getBooking(this.consumer).subscribe((data: any) => {
      this.loading = false;
      if (data.booking) {
        this.populateBookingOptions(data.booking);
        // this.countDown = 11;
      }
    });
  }

  setLastBooking(lst: LastBooking) {
    lst.bdt = moment(lst.bdt);
    this.booking.last_booking = lst;
  }

  ddates: moment.Moment[] = [];
  setBookingOptions(opts: BookingOption[]) {
    this.ddates = [];
    opts = opts.map(o => {
      o.status_desc = null;
      o.status_desc_color = 'inherit';
      o.edd = moment(o.edd);
      
      const da = this.ddates.filter(d => d.isSame(o.edd));
      if (da.length == 0) this.ddates.push(o.edd);

      const [h_s, m_s, s_s] = o.st.split(':');
      o.booking_start = this.booking.server_time.clone().startOf('day').set('hours', parseInt(h_s)).set('minutes', parseInt(m_s)).set('seconds', parseInt(s_s));
      const [h_e, m_e, s_e] = o.et.split(':');
      o.booking_end = this.booking.server_time.clone().startOf('day').set('hours', parseInt(h_e)).set('minutes', parseInt(m_e)).set('seconds', parseInt(s_e));
      return o;
    });

    this.booking.options = opts;
  }

  getBookingOptions(dt: moment.Moment) {
    return this.booking.options.filter(o => o.edd.isSame(dt, 'hours'));
  }

  saveOlpBook(opt: BookingOption) {

    // const href = Location.joinWithSlash(location.href.split('#', 1)[0], '/svr/payment-status.php');
    // // const return_url = 'http://data.cmwssb.in/payment-status.php';
    // const return_url = this.sanitizer.sanitize(SecurityContext.URL, href);
    const return_url = environment.makePaymentReturnURL(this.sanitizer);

    const reqData = {
      zone_no: this.consumer.zone_no,
      ward_no: this.consumer.ward_no,
      bill_no: this.consumer.bill_no,
      sub_code: this.consumer.sub_code,
      mobile_no: this.consumer.mobile_no,
      c_code: opt.c_code,
      edd: opt.edd.format('YYYY-MM-DD'),
      amount: opt.amount,
      return_url: return_url,
      is_msb: this.consumer.is_msb,
      is_staff: this.consumer.is_staff,
      is_commercial: this.consumer.is_commercial,
      mos: opt.mos,
      via: 'i'
    };
    
    this.loading = true;
    this.dataService.makePayment(reqData).subscribe((data: any) => {
      this.loading = false;

      if (data.server_time) {
        this.serverTimeService.updateNewTime(moment(data.server_time).toDate());
      }

      if (data.opt) {
        data.opt.status_desc = null;
        data.opt.status_desc_color = 'inherit';
        data.opt.edd = moment(data.opt.edd);
        const [h_s, m_s, s_s] = data.opt.st.split(':');
        data.opt.booking_start = this.booking.server_time.clone().startOf('day').set('hours', parseInt(h_s)).set('minutes', parseInt(m_s)).set('seconds', parseInt(s_s));
        const [h_e, m_e, s_e] = data.opt.et.split(':');
        data.opt.booking_end = this.booking.server_time.clone().startOf('day').set('hours', parseInt(h_e)).set('minutes', parseInt(m_e)).set('seconds', parseInt(s_e));
        
        // update original opts
        this.booking.options = this.booking.options.map(o => {
          if (o.c_code === opt.c_code && o.mos === opt.mos && o.edd.isSame(opt.edd)) {
            o = Object.assign({}, o, data.opt);
          }
          return o;
        });

        opt = Object.assign({}, opt, data.opt);
      }

      let error_message_data: { title: string, error_message: string, color?: 'red'|'darkgreen', return?: boolean };
      switch (true) {
        case (data.message === 'Payment initiated successfully.'):
          // this.submitBillDesk(data);
          this.submitRzp(data);
          break;
        case (data.message === 'Payment Queue Already Full'):
          error_message_data = {
            error_message: 'Already Payments of other consumers are under verification. Please keep clicking on "Check Status" button to secure your chance for Payment & Booking.',
            title: data.message
          }
          this.startCountDown();
          break;
        default:
          error_message_data = {
            error_message: data.message,
            title: 'Dial for water'
          }
      }
      
      if (error_message_data) {
        this.showErrorMessage(error_message_data);
      }

    });
  }

  private showErrorMessage(error_message_data: { title: string; error_message: string; color?: "red" | "darkgreen"; return?: boolean; }) {
    if (!error_message_data.color)
      error_message_data.color = 'red';
    if (!error_message_data.hasOwnProperty('return'))
      error_message_data.return = true;
    let config: MatDialogConfig = {
      width: '550px',
      disableClose: true,
      hasBackdrop: true,
      data: error_message_data
    };
    this.dialog.open(ErrorMessageDialogComponent, config);
  }

  private submitRzp(data: any) {
    const options = {
      key: data.rzp_key,
      amount: (data.amount * 100), // in paisa
      currency: data.rzp_currency,
      image: './assets/imgs/cmwssb_logo.jpeg',
      name: "CMWSSB",
      description: 'Dial for water 2.0',
      order_id: data.rzp_order_id,
      handler: (response: any) => {
        this.zone.run(() => {
          // console.log('Paid', response);

          const reqData = Object.assign({}, response, { trnx_no_own: data.trnx_no_own });
          this.loading = true;
          this.dataService.capturePayment(reqData).subscribe((resData: any) => {
          this.loading = false;

            if (resData.message === 'Booked successfully.') {

              if (resData.trnx_dt_own) resData.trnx_dt_own = moment(resData.trnx_dt_own);
              if (resData.bdt) resData.bdt = moment(resData.bdt);
              if (resData.edd) resData.edd = moment(resData.edd);

              // this.loading = true;
              this.dataService.setPayment(resData);
              this.router.navigate(['/raz-pay-status'], { skipLocationChange: true }); ///' + data.trnx_no_own
              // setTimeout(() => {
              //   // this.loading = false;
              // }, 100);
                
            } else {
              this.showErrorMessage({ title: 'Payment Error', error_message: resData.message || "Payment failure reported.", color: 'red' });
              this.refreshBookingOptions();
            }
          });
        });
      },
      timeout: 900, // in secs (900 = 15 mins)
      prefill: {
        name: this.consumer.name,
        // email: 'thiru@broadline.co.in',
        contact: this.consumer.mobile_no
      },
      modal: {
        ondismiss: (res: any) => {
          this.zone.run(() => {
            // console.log('Closed', res);
            const reqData = {
              trnx_no_own: data.trnx_no_own,
              reason: (res)?res:'Checkout closed manually'
            };
            this.loading = true;
            this.dataService.cancelledPayment(reqData).subscribe((data: any) => {
              this.loading = false;

              if (data.message === 'Payment cancelled successfully.') {
                this.refreshBookingOptions();
              }
            });
          });
        }
      },
      notes: {
        "CMC No.": this.consumer.zone_no + '/' + this.consumer.ward_no + '/' + this.consumer.bill_no + '/' + this.consumer.sub_code
      }
    };
    // getting the native window obj
    const rzp1 = new this.winRef.nativeWindow.Razorpay(options);
    options.timeout = 100;
    rzp1.open();
  }

  // private submitBillDesk(data: any) {
  //   const frmBD = document.createElement('form');
  //   frmBD.action = this.sanitizer.sanitize(SecurityContext.URL, data.action);
  //   frmBD.method = data.method;
  //   const el = document.createElement("input");
  //   el.name = 'msg';
  //   el.value = data.msg;
  //   frmBD.appendChild(el);
  //   document.body.appendChild(frmBD);
  //   frmBD.submit();
  // }

  disable_booking(opt: BookingOption) {
    if (opt.bal === 0 && opt.init_pay === 0) {
      opt.status_desc = 'Booking over for ' + opt.c_code + ' tankers.'; // 'Booking Closed';
      opt.status_desc_color = "red";
      return true;
    }

    if (this.booking.last_booking) {
      const lb = this.booking.last_booking;
      if (lb.status === 'B' && !lb.is_legecy) {
        opt.status_desc = 'Booking not allowed when previous booking is still open';
        opt.status_desc_color = "red";
        return true;
      }
  
      if (lb.status !== 'C' && lb.lb_nod < lb.sbr && !lb.is_legecy) {
        opt.status_desc = 'Minimum period between consecutive bookings is ' + lb.sbr + ' days.';
        opt.status_desc_color = "red";
        return true;
      }
    }

    if (this.booking.server_time.clone().isBefore(opt.booking_start, 'seconds')) {
      opt.status_desc = 'Booking opens at ' + opt.booking_start.format('hh:mm A');
      opt.status_desc_color = 'green';
      return true;
    }

    if (this.booking.server_time.clone().isAfter(opt.booking_end, 'seconds')) {
      opt.status_desc = 'Booking closed for today.'
      opt.status_desc_color = "red";
      return true;
    }

    // if (opt.mos === 'cod') {
    //   console.log(opt);
    // }

    // for dial for water service 
    // if (opt.mos === 'olp') {
    //   if (this.booking.last_booking && this.booking.last_booking.mos === 'olp' && this.booking.last_booking.bdt.clone().startOf('day').add(7, 'days').isSameOrBefore(opt.edd, 'hours')) {
    //     opt.status_desc = 'Minimum period between consecutive bookings for dial for water service is 7 days.';
    //     opt.status_desc_color = "red";
    //     return true;
    //   }
    // }

    if (opt.bal === 0 && opt.init_pay > 0) {
      opt.status_desc = 'Payment Queue Already Full'; //Awaiting Payment Verification.
      opt.status_desc_color = '#f44336';
      return false;
    }

    return false;
  }
  
  goBack(show_confirm: boolean = false) {
    if (show_confirm && !confirm('Your session will be teriminated.\n\n\nAre you sure to return to home page?')) {
      return;
    }
    
    this.dataService.setCurrentConsumer(null);
    this.router.navigate(['/index'], { skipLocationChange: false });
  }

  @ViewChild('refreshin', { static: false })
  public refreshin: ElementRef;

  @ViewChild('refreshin1', { static: false })
  public refreshin1: ElementRef;

  countDown: number = 0;

  startCountDown(n: number = 31) {
    this.countDown = n;
    setTimeout(() => {
      this.countDown = 0;
    }, (n+1)*1000);
  }
}
