import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';

import { DataService } from '../data.service';

@Component({
  templateUrl: './raz-pay-status.component.html'
})
export class RazPayStatusComponent implements OnInit {
  // trnx_no_own: string;
  payment: any;
  loading = false;

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.payment = this.dataService.getPayment();
  }

  download() {
    this.loading = true;
    this.dataService.makePaymentStatusPdf({ trnx_no_own: this.payment.trnx_no_own }).subscribe((blob: Blob) => {
      this.loading = false;
      FileSaver.saveAs(blob, this.payment.trnx_no_own.toLowerCase() + ".pdf");
    });
  }

  goBack() {
    this.router.navigate(['/index'], { skipLocationChange: false });
  }
}
