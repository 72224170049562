import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';


@Component({
  selector: 'edit-location-detail',
  templateUrl: './edit-location-detail.component.html',
  styleUrls: ['./edit-location-detail.component.scss']
})
export class EditLocationDetailComponent implements OnInit {

  @Input()
  app: Applicant;

  @Input()
  areaTypes: any;

  @Input()
  formType: any;

  @Input()
  action: string;

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  loading = false;
  submitted = false;
  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  // console.log({"app":this.app,"act":this.isAction})
    this.makeFormGroup();
    if(this.action == 'VIEW'){
      this.fg.get('rs_ts_no').disable();
      this.fg.get('district_name').disable();
      this.fg.get('taluk_name').disable();
      this.fg.get('village_id').disable();
      this.fg.get('village_name').disable();
      // this.fg.get('app_city').disable();
      this.fg.get('survey_no').disable();
  
    }
  }

  private makeFormGroup(){

    if(this.isFormType(['F7','F8','F9'])){
      return this.fg = this.fb.group({
        village_id: [this.app.village_id, [Validators.required]],
        village_name: [this.app.village_name, [Validators.required]],
        survey_no: [this.app.survey_no, [Validators.required]],
      })
    }else{
      return this.fg = this.fb.group({
        rs_ts_no: [this.app.rs_ts_no, [Validators.required]],
        district_name: [this.app.district_name, [Validators.required]],
        taluk_name: [this.app.taluk_name, [Validators.required]],
        village_id: [this.app.village_id, [Validators.required]],
        village_name: [this.app.village_name, [Validators.required]],
        survey_no: [this.app.survey_no, [Validators.required]],
      })
    }
    
  }

  isFormType(ut: string | string[]): boolean {
    if (typeof ut === 'string')
      return (this.formType.orgin === ut);
    else {
      const regExp: RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.formType.orgin.match(regExp);
      return match && match.length > 0;
    }
  }

  saveApp() {

    this.submitted = true;

    this.fg.markAsTouched();
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }

}
