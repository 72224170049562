import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Router } from "@angular/router";

import { DataService } from "../data.service";
// import { Consumer } from "../models";
// import { OTPVerificationDialogComponent } from "../dialogs/otp-verification/otp-verification.component";

@Component({
  selector: "search-mobile-no",
  templateUrl: "./search-mobile-no.component.html",
})
export class SearchMobileNoComponent implements OnInit {
  fg: FormGroup;
  submitted = false;
  loading = false;
  error_msg: string;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      mobile_no: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
    });
  }

  search() {
    this.error_msg = null;
    this.submitted = true;
    if (!this.fg.valid) {
      this.error_msg = "Mobile No. is required.";
      return;
    }

    const sValue = this.fg.value;
    this.loading = true;
    this.dataService.searchConsumer(sValue).subscribe((data: any) => {
      this.loading = false;
      // console.log(data);
      if (!data.is_registered) {
        this.dataService.setMobileno(data);
        this.router.navigate(["/register"], { skipLocationChange: true });
        return;
      } else {
        this.error_msg = data.message;
        return;
      }
    });
  }

  // private parseConsumerData(data: any) {
  //   this.dataService.parseConsumerData(data).subscribe((is_error?: boolean) => {
  //     if (is_error) {
  //       this.reset();
  //     } else {
  //       let c: Consumer = data.consumer;
  //       c.via = "i";
  //       this.dataService.setCurrentConsumer(c);

  //       if (data.message === "Generated OTP.") {
  //         this.showOTPValidateDialog(c);
  //       }
  //     }
  //   });
  // }

  reset() {
    this.submitted = false;
    this.fg.get("mobile_no").setValue(null);
    this.fg.markAsPristine();
    this.fg.markAsUntouched();
  }

  // showOTPValidateDialog(consumer: Consumer) {
  //   let config: MatDialogConfig = {
  //     width: "550px",
  //     disableClose: true,
  //     hasBackdrop: true,
  //     data: consumer,
  //   };
  //   const dialogRef = this.dialog.open(OTPVerificationDialogComponent, config);

  //   dialogRef.afterClosed().subscribe((result?: any) => {
  //     if (result) {
  //       // valid OTP returns booking data too
  //       this.router.navigate(["/book"], { skipLocationChange: false });
  //     } else {
  //       // invalid OTP
  //       this.dataService.setCurrentConsumer(null);
  //       this.dataService.setBook(null);
  //       this.reset();
  //     }
  //   });
  // }
}
