import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Router } from "@angular/router";
import * as moment from "moment";

import { DataService } from "../data.service";
import { ShowBookingStatusDialogComponent } from "../dialogs/show-booking-status/show-booking-status.component";
import { LastBooking } from "../models";

@Component({
  selector: "booking-status",
  templateUrl: "./booking-status.component.html",
})
export class BookingStatusComponent implements OnInit {
  fg: FormGroup;
  submitted = false;
  loading = false;
  error_msg: string;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      pwd: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(100),
        ],
      ],
    });
    // this.fg.get('booking_no').setValue('A0726100001');
    // this.fg.get('mobile_no').setValue('9884410001');
  }

  login() {
    this.error_msg = null;
    this.submitted = true;

    if (!this.fg.valid) {
      this.error_msg = "All fields are mandatory.";
      return;
    }

    const sValue = this.fg.value;
    this.loading = true;
    this.dataService.login(sValue).subscribe((data: any) => {
      this.loading = false;

      // console.log(data);
      if (data.message === "User authenticated") {
        localStorage.setItem("fpUser", JSON.stringify(data));
        if (data.group_cd === 'LIC'){
          this.router.navigate(["/consumer"]);
        } else
          this.router.navigate(["/home"]);
      } else {
        alert("Please verify your user email id.");
      }
    });
  }

  // reset() {
  //   this.submitted = false;
  //   this.fg.get('mobile_no').setValue(null);
  //   this.fg.markAsPristine();
  //   this.fg.markAsUntouched();
  // }

  // showBookingStatusDialog(lb: LastBooking) {
  //   let config: MatDialogConfig = {
  //     width: '550px',
  //     disableClose: true,
  //     hasBackdrop: true,
  //     data: lb
  //   };
  //   this.dialog.open(ShowBookingStatusDialogComponent, config);
  // }
}
