import { Component, ViewChild, ElementRef, NgZone, Renderer2, Input } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

import { ServerTimeService } from './server-time.service';

@Component({
  selector: 'server-time',
  templateUrl: './server-time.component.html'
})
export class ServerTimeComponent { // implements OnChanges
  // newServerTime: Subject<Date> = this.serverTimeService.serverTime;

  // @Input()
  // public 
  serverTime: Date; // = moment().toDate();

  @ViewChild('dtnow', { static: false })
  public dtnow: ElementRef;

  constructor(
    private zone: NgZone, 
    private renderer: Renderer2,
    private serverTimeService: ServerTimeService
  ) {
    this.serverTimeService.serverTime.subscribe(newTime => this.serverTime = newTime);

    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        if (this.serverTime) {
          this.serverTime = moment(this.serverTime).add(1, 'second').toDate();
          const displayDateTime = moment(this.serverTime).format('DD/MM/YYYY HH:mm:ss A');
          this.renderer.setProperty(this.dtnow.nativeElement, 'textContent', displayDateTime);
        }
      }, 1000);
    });
  }
}

// 'Date: ' + 
// const displayTime = 'Time: ' + moment(this.serverTime).format('HH:mm:ss A');
// this.renderer.setProperty(this.now.nativeElement, 'textContent', displayTime);
// ngOnChanges(changes: SimpleChanges) {
  // console.log(changes.serverTime.currentValue, changes.serverTime.previousValue);
  // if (changes.serverTime.currentValue !== changes.serverTime.previousValue)
  // {
  //   this.serverTime = changes.serverTime.currentValue;
  // }
// }

  // @ViewChild('now', { static: false })
  // public now: ElementRef;
