import { Component, Input } from '@angular/core';
import * as FileSaver from 'file-saver';

import { LastBooking } from '../models/booking';
import { DataService } from '../data.service';

@Component({
  selector: 'display-last-booked-status',
  templateUrl: './display-last-booked-status.component.html'
})
export class DisplayLastBookedStatusComponent {
  @Input("booking")
  booking: LastBooking;

  loading = false;
  msg: string;
  msg_color: string = 'initial';
  is_resent = false;

  constructor(
    private dataService: DataService
  ) { }

  download() {
    this.loading = true;
    this.dataService.makePaymentStatusPdf({ trnx_no_own: this.booking.trnx_no_own}).subscribe((blob: Blob) => {
      this.loading = false;
      FileSaver.saveAs(blob, this.booking.trnx_no_own.toLowerCase() + ".pdf");
    });
  }

  resendPin() {
    this.loading = true;
    this.dataService.resendPIN(this.booking).subscribe((data: any) => {
      this.loading = false;

      if (data.message === 'PIN resent Successfully.') {
        this.msg = data.message;
        this.msg_color = 'green';
      } else {
        this.msg = data.message;
        this.msg_color = 'red';
      }

      this.is_resent = true;
    });
  }

  getMessage() {
    let status2 = '';
    let status2_color = 'red';
    const lb = this.booking;
    if (!lb.is_legecy) {
      switch(true) {
        case (lb.status === 'B'):
          status2 = 'Booking not allowed when previous booking is still open';
          status2_color = 'red';
          break;
        case (lb.status !== 'C' && lb.lb_nod < lb.sbr):
          status2 = 'Minimum period between consecutive bookings is ' + lb.sbr + ' days.';
          status2_color = 'red';
          break;
      }
    } else {
      if (lb.status === 'B') {
        status2 = 'Booking allowed.';
        status2_color = 'darkgreen';
      }
    }
    return { msg: status2, color: status2_color };
  }
}
