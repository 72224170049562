import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared/shared-routing.module';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditAppDetailComponent } from './shared/form-edit/edit-app-detail/edit-app-detail.component';
import { EditOwnerDetailComponent } from './shared/form-edit/edit-owner-detail/edit-owner-detail.component';
import { EditOccupDetailComponent } from './shared/form-edit/edit-occup-detail/edit-occup-detail.component';
import { EditLocationDetailComponent } from './shared/form-edit/edit-location-detail/edit-location-detail.component';
import { EditWellDetailComponent } from './shared/form-edit/edit-well-detail/edit-well-detail.component';
import { EditGeneralDetailComponent } from './shared/form-edit/edit-general-detail/edit-general-detail.component';
import { EditTransportDetailComponent } from './shared/form-edit/edit-transport-detail/edit-transport-detail.component';
import { EditPumpDetailComponent } from './shared/form-edit/edit-pump-detail/edit-pump-detail.component';
import { EditRefusalDetailComponent } from './shared/form-edit/edit-refusal-detail/edit-refusal-detail.component';
import { EditLicenceeDetailComponent } from './shared/form-edit/edit-licencee-detail/edit-licencee-detail.component';
import { ConsumerProfileComponent } from './shared/view-consumer/view-consumer.component';
import { OthersComponent } from './shared/form-edit/others/others.component';
import { AutofocusDirective } from "./shared.autofocus.directive";
import { DeclarationComponent } from './shared/form-edit/declaration/declaration.component';
import { StatusProgressComponent } from './status-progress/status-progress.component';
import { ConsumerDetailsComponent } from './consumer-details/consumer-details.component';

@NgModule({
  declarations: [
    AutofocusDirective,
    EditAppDetailComponent, 
    EditOwnerDetailComponent,
    EditOccupDetailComponent, 
    EditLocationDetailComponent,
    EditWellDetailComponent,
    EditGeneralDetailComponent,
    EditTransportDetailComponent,
    EditPumpDetailComponent,
    EditRefusalDetailComponent,
    EditLicenceeDetailComponent,
    ConsumerProfileComponent,
    OthersComponent,
    DeclarationComponent,
    StatusProgressComponent,
    ConsumerDetailsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedRoutingModule
  ],
  exports: [
    EditAppDetailComponent,
    EditOwnerDetailComponent, 
    EditOccupDetailComponent, 
    EditLocationDetailComponent,
    EditWellDetailComponent,
    EditGeneralDetailComponent,
    EditTransportDetailComponent,
    EditPumpDetailComponent,
    EditRefusalDetailComponent,
    EditLicenceeDetailComponent,
    ConsumerProfileComponent,
    OthersComponent,
    DeclarationComponent,
    StatusProgressComponent,
    ConsumerDetailsComponent
  ]
})
export class SharedModule { }
