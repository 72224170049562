import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
// import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';


// import { MatCheckboxModule, MatDatepickerModule, , MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatMenuModule, MatSelectModule, MatSlideToggleModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material'
import { FlexLayoutModule } from "@angular/flex-layout";
import { AvatarModule, AvatarSource } from "ngx-avatar";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
// import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ServiceWorkerModule } from "@angular/service-worker";

import { environment } from "../environments/environment";

import { AppRoutingModule } from "./app-routing.module";

// Directives
import { AutofocusDirective } from "./autofocus.directive";

// Providers
import { loaderInterceptorProvider } from "./loader/loader.interceptor";
import { appRequestInterceptorProvider } from "./interceptors/request.interceptor";
import { appResponseInterceptorProvider } from "./interceptors/response.interceptor";

// dialog Components
import { OTPVerificationDialogComponent } from "./dialogs/otp-verification/otp-verification.component";
import { ErrorMessageDialogComponent } from "./dialogs/error-message/error-message.component";
import { ShowBookingStatusDialogComponent } from "./dialogs/show-booking-status/show-booking-status.component";

// Components
import { AppComponent } from "./app.component";
import { LoaderComponent } from "./loader/loader.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { ServerTimeComponent } from "./server-time/server-time.component";
import { SearchCMCNoComponent } from "./search-cmc-no/search-cmc-no.component";
import { SearchMobileNoComponent } from "./search-mobile-no/search-mobile-no.component";
import { BookingStatusComponent } from "./booking-status/booking-status.component";
import { UpdateDeliveredComponent } from "./update-delivered/update-delivered.component";
import { IndexComponent } from "./index/index.component";
import { CRegisterComponent } from "./register/cregister.component";
import { BookComponent } from "./book/book.component";
import { DisplayLastBookedStatusComponent } from "./display-last-booked-status/display-last-booked-status.component";
import { ViewConsumerComponent } from "./view-consumer/view-consumer.component";
import { PaymentStatusComponent } from "./payment-status/payment-status.component";
import { RazPayStatusComponent } from "./raz-pay-status/raz-pay-status.component";
import { HomeComponent } from "./home/home.component";
import { ConsumerComponent } from './consumer/consumer.component';
import { ApplicationComponent } from './application/application.component';

// Custom Shared Components && module

import { SharedModule } from './shared.module';
import { ViewComponent } from './view/view.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ProcessingComponent } from './processing/processing.component';
import { ScheduleAppComponent } from './schedule-app/schedule-app.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { ChecklistViewComponent } from './checklist-view/checklist-view.component';
import { ApprovedComponent } from './approved/approved.component';
import { VerfiedComponent } from './verfied/verfied.component';
import { RejectedComponent } from './rejected/rejected.component';

const avatarSourcesOrder = [
  AvatarSource.CUSTOM,
  AvatarSource.INITIALS,
  AvatarSource.VALUE,
];

@NgModule({
  entryComponents: [
    OTPVerificationDialogComponent,
    ErrorMessageDialogComponent,
    ShowBookingStatusDialogComponent,
  ],
  declarations: [
    AutofocusDirective,

    OTPVerificationDialogComponent,
    ErrorMessageDialogComponent,
    ShowBookingStatusDialogComponent,

    AppComponent,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    ServerTimeComponent,
    SearchCMCNoComponent,
    SearchMobileNoComponent,
    BookingStatusComponent,
    UpdateDeliveredComponent,
    IndexComponent,
    CRegisterComponent,
    BookComponent,
    DisplayLastBookedStatusComponent,
    ViewConsumerComponent,
    PaymentStatusComponent,
    RazPayStatusComponent,
    HomeComponent,
    ConsumerComponent,
    ApplicationComponent,
    ViewComponent,
    ScheduleComponent,
    ProcessingComponent,
    ScheduleAppComponent,
    ChecklistComponent,
    ChecklistViewComponent,
    ApprovedComponent,
    VerfiedComponent,
    RejectedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    // Shared
    SharedModule,
    MatDatepickerModule,
    MatRadioModule,
    AvatarModule.forRoot({
      sourcePriorityOrder: avatarSourcesOrder,
    }),
    FontAwesomeModule,

    AppRoutingModule,

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    loaderInterceptorProvider,
    appRequestInterceptorProvider,
    appResponseInterceptorProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faClock);
  }
}
