import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Router } from "@angular/router";

import { Consumer } from "../models";
import { DataService } from "../data.service";
// import { OTPVerificationDialogComponent } from '../dialogs/otp-verification/otp-verification.component';

@Component({
  templateUrl: "./cregister.component.html",
})
export class CRegisterComponent implements OnInit {
  mobileno: any;
  fg: FormGroup;
  submitted = false;
  loading = false;
  error_msg: string;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.mobileno = this.dataService.getCurrentMobileno();
    if (this.mobileno) {
      this.fg = this.fb.group({
        mobile_no: [this.mobileno.mobile_no],
        name: [null, [Validators.required, Validators.maxLength(100)]],
        email: [null, [Validators.required, Validators.email]],
        pwd: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(100),
          ],
        ],
        // cpwd: [
        //   null,
        //   [
        //     Validators.required,
        //     Validators.minLength(8),
        //     Validators.maxLength(100),
        //   ],
        // ],
      });
    }
  }

  register() {
    this.error_msg = null;
    this.submitted = true;
    if (!this.fg.valid) {
      this.error_msg = "All field are required.";
      return;
    }

    const sValue = this.fg.value;
    this.loading = true;
    // console.log(sValue);
    this.dataService.saveConsumer(sValue).subscribe((data: any) => {
      this.loading = false;
      console.log(data);
      if (data.message === "Consumer Saved Successfully.") {
        // this.dataService.removeMobileno();
        this.router.navigate(["/index"]);
        return;
      } else {
        this.error_msg = "Server Busy";
        return;
      }
    });
  }

  goBack() {
    // this.dataService.setCurrentConsumer(null);
    this.router.navigate(["/index"], { skipLocationChange: true });
  }
}
