import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { LastBooking } from '../../models';

@Component({
  templateUrl: './show-booking-status.component.html',
  styles: []
})
export class ShowBookingStatusDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public lb: LastBooking,
    private dialogRef: MatDialogRef<ShowBookingStatusDialogComponent>
  ) { }

  ngOnInit() {
  }
  
  cancel() {
    this.dialogRef.close(false);
  }
}
