import { Component, OnInit, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit, OnDestroy {
  private alive = true;

  constructor(
    // private router: Router,
    private swUpdate: SwUpdate
  ) { }

  ngOnInit() {
    this.swUpdate.available.pipe(
      takeWhile(() => this.alive)
    ).subscribe(() => {
      if (confirm("New version available.\n\nWe recommend you to load new version.\n\nDo you want to load New Version?")) {
        window.location.reload();
      }
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
