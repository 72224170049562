import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';

@Component({
  selector: 'edit-owner-detail',
  templateUrl: './edit-owner-detail.component.html',
  styleUrls: ['./edit-owner-detail.component.scss']
})
export class EditOwnerDetailComponent implements OnInit {

  @Input()
  app: Applicant;

  @Input()
  action: string;

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  loading = false;
  submitted = false;
  fg: FormGroup;

  disableCheck = true;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  console.log(this.app)
    this.makeFormGroup();

    if(this.action == 'VIEW'){
      this.fg.get('own_name').disable();
      this.fg.get('is_owner_as_app').disable();
      this.fg.get('own_address').disable();
      this.fg.get('own_area').disable();
      this.fg.get('own_city').disable();
      this.fg.get('own_pincode').disable();
  
    }

    if(this.app.is_owner_as_app){
      this.fg.get('own_name').setValue(this.app.app_name);
      this.fg.get('own_address').setValue(this.app.app_address);
      this.fg.get('own_area').setValue(this.app.app_area);
      this.fg.get('own_city').setValue(this.app.app_city);
      this.fg.get('own_pincode').setValue(this.app.app_pincode);
      
      this.fg.get('own_name').disable();
      this.fg.get('own_address').disable();
      this.fg.get('own_area').disable();
      this.fg.get('own_city').disable();
      this.fg.get('own_pincode').disable();
    }else{
      this.fg.get('own_name').enable();
      this.fg.get('own_address').enable();
      this.fg.get('own_area').enable();
      this.fg.get('own_city').enable();
      this.fg.get('own_pincode').enable();

      this.fg.get('own_name').setValue('');
      this.fg.get('own_address').setValue('');
      this.fg.get('own_area').setValue('');
      this.fg.get('own_city').setValue('');
      this.fg.get('own_pincode').setValue('');
    }

  }

  private makeFormGroup(){
    this.fg = this.fb.group({
      is_owner_as_app: [this.app.is_owner_as_app, [Validators.required]],
      own_name: [this.app.own_name, [Validators.required]],
      own_address: [this.app.own_address, [Validators.required]],
      own_area: [this.app.own_area, [Validators.required]],
      own_city: [this.app.own_city, [Validators.required]],
      own_pincode: [this.app.own_pincode, [Validators.required]],
    })
  }

  saveApp() {

    this.submitted = true;

    this.fg.markAsTouched();
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }

  whereOwner(d:any){
    console.log(d.value);
    if(d.value){

      // this.fg = this.fb.group({
      //   is_owner_as_app: [true, [Validators.required]],
      //   own_name: [{value:this.app.app_name, disabled:(this.app.app_name)?true:false}, (this.app.app_name)?true:[Validators.required]],
      //   own_address: [{value:this.app.app_address,disabled:(this.app.app_address)?true:false}, (this.app.app_name)?true:[Validators.required]],
      //   own_area: [{value:this.app.app_area,disabled:(this.app.app_area)?true:false}, (this.app.app_name)?true:[Validators.required]],
      //   own_city: [{value:this.app.app_city,disabled:(this.app.app_city)?true:false}, (this.app.app_name)?true:[Validators.required]],
      //   own_pincode: [{value:this.app.app_year,disabled:(this.app.app_year)?true:false}, (this.app.app_name)?true:[Validators.required]],
      // })

      this.fg.get('own_name').setValue(this.app.app_name);
      this.fg.get('own_address').setValue(this.app.app_address);
      this.fg.get('own_area').setValue(this.app.app_area);
      this.fg.get('own_city').setValue(this.app.app_city);
      this.fg.get('own_pincode').setValue(this.app.app_pincode);
      
      this.fg.get('own_name').disable();
      this.fg.get('own_address').disable();
      this.fg.get('own_area').disable();
      this.fg.get('own_city').disable();
      this.fg.get('own_pincode').disable();

    }else{

      this.fg.get('own_name').enable();
      this.fg.get('own_address').enable();
      this.fg.get('own_area').enable();
      this.fg.get('own_city').enable();
      this.fg.get('own_pincode').enable();

      this.fg.get('own_name').setValue('');
      this.fg.get('own_address').setValue('');
      this.fg.get('own_area').setValue('');
      this.fg.get('own_city').setValue('');
      this.fg.get('own_pincode').setValue('');

      // this.fg = this.fb.group({
      //   is_owner_as_app: [false, [Validators.required]],
      //   own_name: [{value:this.app.own_name, disabled:false}, [Validators.required]],
      //   own_address: [{value:this.app.own_address,disabled:false}, [Validators.required]],
      //   own_area: [{value:this.app.own_area,disabled:false}, [Validators.required]],
      //   own_city: [{value:this.app.own_city,disabled:false}, [Validators.required]],
      //   own_pincode: [{value:this.app.own_pincode,disabled:false}, [Validators.required]],
      // })
    }
  }

}