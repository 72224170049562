import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';

@Component({
  selector: 'app-edit-transport-detail',
  templateUrl: './edit-transport-detail.component.html',
  styleUrls: ['./edit-transport-detail.component.scss']
})
export class EditTransportDetailComponent implements OnInit {

  @Input()
  app: Applicant;

  @Input()
  transport: any;

  @Input()
  formType: any;

  @Input()
  action: string;

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  loading = false;
  submitted = false;
  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  // console.log({"app":this.app,"act":this.isAction})
    this.makeFormGroup();
    if(this.action == 'VIEW'){
      this.fg.get('other_trans_type').disable();
      this.fg.get('trans_lic_no').disable();
      this.fg.get('capacity_tank').disable();
      this.fg.get('no_of_trips').disable();
      this.fg.get('trans_type').disable();
      // this.fg.get('app_city').disable();
      // this.fg.get('app_pincode').disable();
  
    }
  }

  private makeFormGroup(){
    this.fg = this.fb.group({
      other_trans_type: [this.app.other_trans_type, [Validators.required]],
      trans_lic_no: [this.app.trans_lic_no, [Validators.required]],
      capacity_tank: [this.app.capacity_tank, [Validators.required]],
      no_of_trips: [this.app.no_of_trips, [Validators.required]],
      trans_type: [this.app.trans_type, [Validators.required]],
    })
  }

  onChangeMode(event){
    if(event.value == 3){
      this.fg.get('other_trans_type').enable();
    }else{
      this.fg.get('other_trans_type').disable();
    }
  }

  saveApp() {

    this.submitted = true;

    this.fg.markAsTouched();
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }

}
