import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';

import { DataService } from '../data.service';

@Component({
  templateUrl: './payment-status.component.html'
})
export class PaymentStatusComponent implements OnInit {
  trnx_no_own: string;
  payment: any;
  loading = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService
  ) {
    this.trnx_no_own = this.activeRoute.snapshot.paramMap.get('tno');
  }

  ngOnInit() {
    if (this.trnx_no_own) {
      this.getPaymentStatus();
    } else {
      this.goBack();
    }
  }

  getPaymentStatus() {
    this.loading = true;
    this.dataService.getPaymentStatus({ trnx_no_own: this.trnx_no_own}).subscribe((data: any) => {
      this.loading = false;
      if (data) {
        this.payment = data;
        if (data.trnx_dt_own) data.trnx_dt_own = moment(data.trnx_dt_own);
        if (data.bdt) data.bdt = moment(data.bdt);
        if (data.edd) data.edd = moment(data.edd);
      }
    });
  }

  download() {
    this.loading = true;
    this.dataService.makePaymentStatusPdf({ trnx_no_own: this.trnx_no_own}).subscribe((blob: Blob) => {
      this.loading = false;
      FileSaver.saveAs(blob, this.trnx_no_own.toLowerCase() + ".pdf");
    });
  }

  goBack() {
    this.router.navigate(['/index'], { skipLocationChange: false });
  }
}
