import { NgModule, LOCALE_ID } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  MatButtonModule,
  MatInputModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatCardModule,
  MatSnackBarModule,
  MatSelectModule,
  MatExpansionModule,
  MatToolbarModule,
  MatListModule,
  // MatPaginatorModule,
  MatTabsModule,
  MatTooltipModule,
  MatBadgeModule,
  // MatChipsModule,
  MatFormFieldModule,
  MatProgressBarModule,
  MatStepperModule,
  MatCheckboxModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  DateAdapter,
  // NativeDateAdapter,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS
} from '@angular/material';

import { FlexLayoutModule } from '@angular/flex-layout';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatSnackBarModule,
    MatSelectModule,
    MatExpansionModule,
    MatToolbarModule,
    MatListModule,
    // MatPaginatorModule,
    MatTabsModule,
    MatTooltipModule,
    MatBadgeModule,
    // MatChipsModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FlexLayoutModule
  ],
  exports: [
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatSnackBarModule,
    MatSelectModule,
    MatExpansionModule,
    MatToolbarModule,
    MatListModule,
    // MatPaginatorModule,
    MatTabsModule,
    MatTooltipModule,
    MatBadgeModule,
    // MatChipsModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FlexLayoutModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-IN' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class MaterialModule { }
