import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';

@Component({
  selector: 'app-edit-pump-detail',
  templateUrl: './edit-pump-detail.component.html',
  styleUrls: ['./edit-pump-detail.component.scss']
})
export class EditPumpDetailComponent implements OnInit {

  @Input()
  app: Applicant;

  @Input()
  pumps: any;

  @Input()
  formType: any;
  @Input()
  action: string;

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  loading = false;
  submitted = false;
  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  // console.log({"app":this.app,"act":this.isAction})
    this.makeFormGroup();
    if(this.action == 'VIEW'){
      this.fg.get('pump_capty').disable();
      this.fg.get('pump_hp').disable();
      this.fg.get('pump_eb_no').disable();
      // this.fg.get('app_address').disable();
      this.fg.get('pump_hr_pd').disable();
      this.fg.get('pump_type').disable();
      // this.fg.get('app_pincode').disable();
  
    }
  }

  private makeFormGroup(){
    this.fg = this.fb.group({
      pump_capty: [this.app.pump_capty, [Validators.required]],
      pump_eb_no: [this.app.pump_eb_no, [Validators.required]],
      pump_hp: [this.app.pump_hp, [Validators.required]],
      pump_hr_pd: [this.app.pump_hr_pd, [Validators.required]],
      pump_type: [this.app.pump_type, [Validators.required]],
    })
  }

  saveApp() {

    this.submitted = true;

    this.fg.markAsTouched();
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }

}
