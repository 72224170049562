import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { DataService } from '../data.service';

@Component({
  selector: 'search-cmc-no',
  templateUrl: './search-cmc-no.component.html'
})
export class SearchCMCNoComponent implements OnInit {
  focusID = 1;
  fg: FormGroup;
  submitted = false;
  loading = false;
  error_msg: string;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      zone_no: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      ward_no: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      bill_no: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      sub_code: ['000', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]]
    });

    // New
    // this.fg.get('zone_no').setValue('10');
    // this.fg.get('ward_no').setValue('130');
    // this.fg.get('bill_no').setValue('00202');

    // Normal
    // this.fg.get('zone_no').setValue('10');
    // this.fg.get('ward_no').setValue('128');
    // this.fg.get('bill_no').setValue('13423');

    // // Arrears
    // this.fg.get('zone_no').setValue('05');
    // this.fg.get('ward_no').setValue('057');
    // this.fg.get('bill_no').setValue('05583');

    // // Arrears
    // this.fg.get('zone_no').setValue('01');
    // this.fg.get('ward_no').setValue('010');
    // this.fg.get('bill_no').setValue('01016');
    
    // Non-MSB
    // 10/128/13426/000
    // MSB
    // 10/130/00150/000
    // with Arrears
    // 10/128/13424/000
    // Commercial
    // 10/140/03965/000
  }

  onSearchChange(ID: number, searchValue : string) {
    this.focusID = 0;
    if (ID === 1 && searchValue.length === 2) this.focusID = 2;
    if (ID === 2 && searchValue.length === 0) this.focusID = 1;
    if (ID === 2 && searchValue.length === 3) this.focusID = 3;
    if (ID === 3 && searchValue.length === 0) this.focusID = 2;
    if (ID === 3 && searchValue.length === 5) this.focusID = 4;
    if (ID === 4 && searchValue.length === 0) this.focusID = 3;
  }

  search() {
    this.error_msg = null;
    this.submitted = true;
    if (!this.fg.valid)
    {
      this.error_msg = 'CMC No. is required.';
      return;
    }

    const sValue = this.fg.value;
    this.loading = true;
    this.dataService.searchConsumer(sValue).subscribe((data: any) => {
      this.loading = false;

      this.parseConsumerData(data);
    });
  }

  private parseConsumerData(data: any) {
    this.dataService.parseConsumerData(data).subscribe((is_error?: boolean) => {
      if (is_error) {
        this.reset();
      }
      else {
        data.consumer.via = 'i';
        this.dataService.setCurrentConsumer(data.consumer);
        // console.log(data.consumer);
        this.router.navigate(['/register'], { skipLocationChange: true });
      }
    });
  }

  reset() {
    this.submitted = false;
    this.fg.get('zone_no').setValue(null);
    this.fg.get('ward_no').setValue(null);
    this.fg.get('bill_no').setValue(null);
    this.fg.get('sub_code').setValue('000');
    this.fg.markAsPristine();
    this.fg.markAsUntouched();
  }
}
