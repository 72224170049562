import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
// import { DataService } from '../../../data.service';

@Component({
  selector: 'app-edit-general-detail',
  templateUrl: './edit-general-detail.component.html',
  styleUrls: ['./edit-general-detail.component.scss']
})
export class EditGeneralDetailComponent implements OnInit {
  focusID = 1;
  division: any;

  @Input()
  app: Applicant;

  @Input()
  zones: any;

  @Input()
  divisions:any;

  @Input()
  action: string;

  @Output()
  depots:EventEmitter<any> = new EventEmitter<any>();

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  loading = false;
  submitted = false;
  fg: FormGroup;

  zone: string;
  ward: string;
  bill: string;

  constructor(
    private fb: FormBuilder,
    // private dataService: DataService,
  ) { }

  ngOnInit() {
    // console.log(this.app);
    this.division = this.divisions;
    if(this.app.cmc_no){
      this.zone = this.app.cmc_no.slice(0,2);
      this.ward = this.app.cmc_no.slice(2,5);
      this.bill = this.app.cmc_no.slice(5,10);

    }

    this.makeFormGroup();
      if(this.action == 'VIEW'){
        this.fg.get('zone_no').disable();
        this.fg.get('ward_no').disable();
        this.fg.get('bill_no').disable();
        this.fg.get('sub_code').disable();
        this.fg.get('area').disable();
        this.fg.get('division').disable();

      }
  }

  private makeFormGroup(){
    this.fg = this.fb.group({
      zone_no: [(this.zone)?this.zone:null, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      ward_no: [(this.ward)?this.ward:null, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      bill_no: [(this.bill)?this.bill:null, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      sub_code: ['000', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      area: [(this.app.area)?this.app.area:null, [Validators.required]],
      division: [(this.app.division)?this.app.division:null, [Validators.required]],
    })
  }

  onSearchChange(ID: number, searchValue : string) {
    this.focusID = 0;
    if (ID === 1 && searchValue.length === 2) this.focusID = 2;
    if (ID === 2 && searchValue.length === 0) this.focusID = 1;
    if (ID === 2 && searchValue.length === 3) this.focusID = 3;
    if (ID === 3 && searchValue.length === 0) this.focusID = 2;
    if (ID === 3 && searchValue.length === 5) this.focusID = 4;
    if (ID === 4 && searchValue.length === 0) this.focusID = 3;
  }

  saveGeneral() {

    this.submitted = true;
    console.log("General Details");

    this.fg.markAsTouched();
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      console.log(this.app);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }

  
  OnChangeDopets(e:any) {
    // console.log(this.divisions);
    this.division = this.divisions.filter((r)=>{
      return r.area_id == e.value;
    })
    // this.depots.emit(e.value); // this.update_vStatus();

    // this.dataService.getDepots({area:e.value}).subscribe((depot) => {
    //   this.division = depot;
    //   console.log(this.division);
    // });
  }
}
