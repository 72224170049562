import { Component, Input } from '@angular/core';

import { User } from '../../models/signup';

@Component({
  selector: 'consumer-profile',
  templateUrl: './view-consumer.component.html',
  styleUrls:['./view-consumer.component.scss']
})
export class ConsumerProfileComponent {
  @Input()
  user: User;
}
