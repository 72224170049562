import { Component, OnInit, SecurityContext } from '@angular/core';
import { AbstractControl, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';
import * as moment from 'moment';

import { environment } from '../../environments/environment';
import { DataService } from '../data.service';
import { ApplicantDetail, OwnerDetail, OccupantDetail, LicenseeDetail, AppGeneral, LandAck, WaterAck, Transport, Pump, RefusalForms, Well, RefusalCriteria, LocationDetail } from '../models/application';
import { Applicant } from '../models/app-details';
import { User } from '../models/signup';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html'
})
export class ApplicationComponent implements OnInit {

  application: any;
  form_type: any;
  Action: any;
  vStatus: string;
  fg: FormGroup;
  user: User;
  Action_type: string;
  isOpen: boolean;
  submitted = false;
  loading = false;
  error_msg: string;

  app: Applicant;
  checking: any;

  // options
  zones: any;
  depots:any;
  proposed:any;
  pumps: any;
  crops: any;
  wells: any;
  areaTypes: any;
  transport: any;
  decl: any;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {

    this.isOpen = false;
    let data = JSON.parse(localStorage.getItem("fpApplication"));
    this.application = data.data;
    this.form_type = data.formD;

    console.log(this.form_type);
    this.Action = data.ActionType;
    this.user = this.dataService.getCurrentUser();

    this.dataService.getZones({}).subscribe((zone) => {
      this.zones = zone;
    });

    this.dataService.getAreaTypes({}).subscribe((areas) => {
      this.areaTypes = areas;
    });
    this.dataService.getPumps({}).subscribe((pumps) => {
      this.pumps = pumps;
    });
    this.dataService.getWells({}).subscribe((wells) => {
      this.wells = wells;
    });
    this.dataService.getProposed({}).subscribe((proposed) => {
      this.proposed = proposed;
    });
    this.dataService.getCrops({}).subscribe((crops) => {
      this.crops = crops;
    });
    this.dataService.getTransport({}).subscribe((transport) => {
      this.transport = transport;
    });

    this.dataService.getDepots({}).subscribe((depot) => {
      this.depots = depot;
    });

    this.dataService.getDecl({}).subscribe((decl: any[]) => {
      this.decl = decl.filter((r) => {
        return r.forms.includes(this.form_type.orgin)
        });
    });

    this.dataService.getApplicationDetail(this.application).subscribe((e: any) => {
      this.app = e
      if (e.id) {
      this.isOpen = true;
      }

    });


    // this.updateDate(this.data.data);
    // Object.assign({"user_id":this.user.id});


    if (this.Action == 'NEW' || this.Action == 'EDIT') {
      this.Action_type = 'EDIT';
    }

    // this.fg = this.fb.group(this.application);

  }

  // isActionType(ut: string | string[]): boolean {
  //   if (typeof ut == 'string')
  //     return (this.Action.vStatus == ut);
  //   else {
  //     const regExp: RegExp = new RegExp((ut as string[]).join("|"));
  //     const match = this.Action.vStatus.match(regExp);
  //     return match && match.length > 0;
  //   }
  // }

  isFormType(ut: string | string[]): boolean {
    if (typeof ut == 'string')
      return (this.form_type.orgin == ut);
    else {
      const regExp: RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.form_type.orgin.match(regExp);
      return match && match.length > 0;
    }
  }

  SubmitApp(){
    this.submitted = true;

    // this.fg.markAsTouched();
    // const sValue = this.fg.value;
    // console.log(sValue);
    if (this.app) {
      this.loading = true;
      this.dataService.saveSubmit(this.app).subscribe((e: any) => {
        if (e.message == 'Application Details Saved successfully.') {
          this.router.navigate(["/consumer"]);
          // this.updateDate(this.app)
        } else {
          this.error_msg = "failed";
          console.log("error");
        }
      });
    }
  }
  getChecked(e: any,i : number){
    if(e.checked){
      this.submitted = false;
    }else{
      this.submitted = true;

      // this.checking= this.checking.filter((m)=>{ m=!m});
    }
    // e.checked
    // console.log(e);
  }

  updateDate(data: any) {
    this.dataService.getApplicationDetail(data).subscribe((e: any) => {
      // this.app = e;
      Object.assign(this.app, e)
    });
  }

  onChangeDepots(d:any){
    this.depots = this.depots.filter((r)=>{
      return r.area_id == d;
    })
    // this.dataService.getDepots({area:d}).subscribe((depot) => {
    //   this.depots = depot;
    // });
  }

  onSavedGeneral(a: any) {
    Object.assign(this.app, a)
    // console.log(this.app);
    this.dataService.saveGeneral(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.app)
      } else {
        this.error_msg = "failed";
        console.log("error");
      }
    });
  }

  onSavedLicense(a: any) {
    Object.assign(this.app, a)
    this.dataService.savelicensee(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.app)
      } else {
        console.log("error");
      }
    });
  }
  onSavedOthers(a: any) {
    Object.assign(this.app, a)
    this.dataService.saveOthers(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.app)
      } else {
        console.log("error");
      }
    });
  }
  onSavedTransport(a: any) {
    Object.assign(this.app, a)
    this.dataService.saveTransport(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.app)
      } else {
        console.log("error");
      }
    });
  }
  onSavedRefusal(a: any) {
    Object.assign(this.app, a)
    this.dataService.saveRefusal(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.app)
      } else {
        console.log("error");
      }
    });
  }
  onSavedApp(a: any) {
    Object.assign(this.app, a)
    this.dataService.saveApp(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.app)
      } else {
        this.error_msg = "failed";
        console.log("error");
      }
    });
  }

  
  onSavedOwn(a: any) {
    Object.assign(this.app, a)
    console.log(this.app);
    this.dataService.saveOwn(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.app)
      } else {
        this.error_msg = "failed";
        console.log("error");
      }
    });
  }

  onSavedOccupant(a: any) {
    Object.assign(this.app, a)
    this.dataService.saveOccup(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.application)
      } else {
        this.error_msg = "failed";
        console.log("error");
      }
    });
  }


  onSavedLocation(a: any) {
    Object.assign(this.app, a)
    this.dataService.saveLocation(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.application)
      } else {
        this.error_msg = "failed";
        console.log("error");
      }
    });
  }

  onSavedPump(a: any) {
    Object.assign(this.app, a)
    this.dataService.savePump(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.application)
      } else {
        this.error_msg = "failed";
        console.log("error");
      }
    });
  }

  onSavedWell(a: any) {
    Object.assign(this.app, a)
    // console.log(this.app);
    this.dataService.saveWells(this.app).subscribe((e: any) => {
      if (e.message == 'Application Details Saved successfully.') {
        this.updateDate(this.application)
      } else {
        this.error_msg = "failed";
        console.log("error");
      }
    });
  }

}
