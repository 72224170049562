import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';

@Component({
  selector: 'edit-well-detail',
  templateUrl: './edit-well-detail.component.html',
  styleUrls: ['./edit-well-detail.component.scss']
})
export class EditWellDetailComponent implements OnInit {

  @Input()
  app: Applicant;

  @Input()
  wells: any;

  @Input()
  formType: any;

  @Input()
  action: string;

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  loading = false;
  submitted = false;
  fg: FormGroup;

  status: any[];

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  // console.log({"app":this.app,"act":this.isAction})
  // this.fg = this.fb.group({
  //   wellsItems: this.fb.array([ this.makeFormGroup() ])
  // });
  this.status = [{name: 'Existing',value:'1'},{name: 'Proposed',value:'2'}];

  this.fg = this.fb.group({
    wellsItems: this.fb.array([
      this.makeFormGroup()
    ])
  })
  if(this.action == 'VIEW'){
      // this.wellArray.at(i).get('bore_dia_well').enable();
      // this.wellArray.at(i).get('bore_dia_well').enable();
      // this.wellArray.at(i).get('bore_dia_well').enable();
      // this.wellArray.at(i).get('bore_dia_well').enable();
      // this.wellArray.at(i).get('bore_dia_well').enable();
      // this.wellArray.at(i).get('bore_dia_well').enable();
      // this.wellArray.at(i).get('bore_dia_well').enable();
      // this.wellArray.at(i).get('bore_dia_well').enable();

  }
  }



  isFormType(ut: string | string[]): boolean {
    if (typeof ut === 'string')
      return (this.formType.orgin === ut);
    else {
      const regExp: RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.formType.orgin.match(regExp);
      return match && match.length > 0;
    }
  }

  OnChangeWell(e: any, i : number){
    if(e.value == 5 || e.value == 2){
      this.wellArray.at(i).get('bore_dia_well').enable();
      this.wellArray.at(i).get('bore_depth_well').enable();
    }else{
      this.wellArray.at(i).get('bore_dia_well').disable();
      this.wellArray.at(i).get('bore_depth_well').disable();

    }
  }

  addSecurityDepostiy() {
    const creds = <FormArray>this.fg.controls['wellsItems'];
    creds.push(this.makeFormGroup());
  }

  removeSecurityDeposity(i: number) {
    const control = <FormArray>this.fg.controls['wellsItems'];
    control.removeAt(i);
  }


  makeFormGroup(){

    
    if(this.isFormType(['F01','F02'])){
        return this.fb.group({
        well_type_id: [this.app.well_type_id,[Validators.required]],
        diameter_well: [this.app.diameter_well, [Validators.required]],
        depth_well: [this.app.depth_well, [Validators.required]],
        bore_dia_well: [{value:this.app.bore_dia_well, disabled: true}, [Validators.required]],
        bore_depth_well: [{value:this.app.bore_depth_well ,disabled: true},[Validators.required]],
        date_constr_well: [this.app.date_constr_well, [Validators.required]],
      })
      } else if(this.isFormType(['F2'])){
        return this.fb.group({
          well_type_id: [this.app.well_type_id, [Validators.required]],
          diameter_well: [this.app.diameter_well, [Validators.required]],
          depth_well: [this.app.depth_well, [Validators.required]],
          bore_dia_well: [{value:this.app.bore_dia_well,disabled: true}, [Validators.required]],
          bore_depth_well: [{value:this.app.bore_depth_well,disabled: true},[Validators.required]],
          // date_constr_well: [this.app.date_constr_well, [Validators.required]],
          iswellstatus: [this.app.iswellstatus, [Validators.required]],
          islicencee_no: [this.app.islicencee_no, [Validators.required]],
        })
      }  else if(this.isFormType(['F1','F3','F4','F5'])){
        return this.fb.group({
          well_type_id: [this.app.well_type_id, [Validators.required]],
          diameter_well: [this.app.diameter_well, [Validators.required]],
          depth_well: [this.app.depth_well, [Validators.required]],
          bore_dia_well: [{value:this.app.bore_dia_well,disabled: true}, [Validators.required]],
          bore_depth_well: [{value:this.app.bore_depth_well,disabled: true},[Validators.required]],
        })
      }   
   
  }

  get wellArray() {
    return <FormArray>this.fg.get('wellsItems');
  }

  saveApp() {

    this.submitted = true;

    this.fg.markAsTouched();
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }

}
