import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';

@Component({
  selector: 'app-declaration',
  templateUrl: './declaration.component.html',
  styles: []
})
export class DeclarationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
