import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { DataService } from '../../data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }
  
  canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedUserGroups = next.data.expectedUserGroups as string[];
    const user = this.dataService.getCurrentUser();
    if (user && user.group_cd && expectedUserGroups && expectedUserGroups.indexOf(user.group_cd) >= 0) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { errMessage: 'You tried to access unauthorized page.' } }); // returnUrl: state.url, 
    return false;
  }
  
}
