import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';


@Component({
  selector: 'app-edit-refusal-detail',
  templateUrl: './edit-refusal-detail.component.html',
  styleUrls: ['./edit-refusal-detail.component.scss']
})
export class EditRefusalDetailComponent implements OnInit {

  @Input()
  app: Applicant;

  @Input()
  action: string;

  @Input()
  formType: any;

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  loading = false;
  submitted = false;
  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  // console.log({"app":this.app,"act":this.isAction})
    this.makeFormGroup();
    if(this.action == 'VIEW'){
      this.fg.get('quantity_criteria').disable();
      this.fg.get('distance_criteria').disable();
      this.fg.get('app_fath_hub_name').disable();
      this.fg.get('density_criteria').disable();
      this.fg.get('other_reason').disable();
      this.fg.get('quality_criteria').disable();
      this.fg.get('trans_hazard').disable();
      this.fg.get('overdrawal').disable();

  
    }

  }

  isFormType(ut: string | string[]): boolean {
    if (typeof ut === 'string')
      return (this.formType.orgin === ut);
    else {
      const regExp: RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.formType.orgin.match(regExp);
      return match && match.length > 0;
    }
  }

  private makeFormGroup(){

    if(this.isFormType(['F7','F8'])){
      return this.fg = this.fb.group({
      quantity_criteria: [this.app.quantity_criteria, [Validators.required]],
      distance_criteria: [this.app.distance_criteria, [Validators.required]],
      density_criteria: [this.app.density_criteria, [Validators.required]],
      other_reason: [this.app.other_reason, [Validators.required]],
      quality_criteria: [this.app.quality_criteria, [Validators.required]],

    })
    } else if(this.isFormType(['F9'])){
      return this.fg = this.fb.group({
        // quantity_criteria: [this.app.quantity_criteria, [Validators.required]],
        // distance_criteria: [this.app.distance_criteria, [Validators.required]],
        // density_criteria: [this.app.density_criteria, [Validators.required]],
        trans_hazard: [this.app.trans_hazard, [Validators.required]],
        overdrawal: [this.app.overdrawal, [Validators.required]],
        other_reason: [this.app.other_reason, [Validators.required]],
        quality_criteria: [this.app.quality_criteria, [Validators.required]],
  
      })
    }
  }

  saveApp() {

    this.submitted = true;

    this.fg.markAsTouched();
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }

}
