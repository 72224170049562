import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';


@Component({
  selector: 'edit-apt-detail',
  templateUrl: './edit-app-detail.component.html',
  styleUrls: ['./edit-app-detail.component.scss']
})
export class EditAppDetailComponent implements OnInit {

  @Input()
  app: Applicant;

  @Input()
  action: string;

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  loading = false;
  submitted = false;
  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  // console.log(this.app)
  this.makeFormGroup();
  if(this.action == 'VIEW'){
    this.fg.get('app_name').disable();
    this.fg.get('app_age').disable();
    this.fg.get('app_fath_hub_name').disable();
    this.fg.get('app_address').disable();
    this.fg.get('app_area').disable();
    this.fg.get('app_city').disable();
    this.fg.get('app_pincode').disable();

  }

  }

  private makeFormGroup(){
    this.fg = this.fb.group({
      app_name: [this.app.app_name, [Validators.required]],
      app_age: [this.app.app_age, [Validators.required]],
      app_fath_hub_name: [this.app.app_fath_hub_name, [Validators.required]],
      app_address: [this.app.app_address, [Validators.required]],
      app_area: [this.app.app_area, [Validators.required]],
      app_city: [this.app.app_city, [Validators.required]],
      app_pincode: [this.app.app_pincode, [Validators.required]],
      // app_year: [this.app.app_year, [Validators.required]],
    })
  }

  saveApp() {

    this.submitted = true;
    console.log("Application Details");

    this.fg.markAsTouched();
    // console.log(this.fg);
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      // console.log(this.app);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }


}
