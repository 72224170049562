import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./index/index.component";
import { CRegisterComponent } from "./register/cregister.component";
import { BookComponent } from "./book/book.component";
import { PaymentStatusComponent } from "./payment-status/payment-status.component";
import { RazPayStatusComponent } from "./raz-pay-status/raz-pay-status.component";
import { HomeComponent } from "./home/home.component";
import { ConsumerComponent } from './consumer/consumer.component';
import { ApplicationComponent } from './application/application.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ProcessingComponent } from './processing/processing.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { ViewComponent } from './view/view.component';
import { ChecklistViewComponent } from './checklist-view/checklist-view.component';
import { ApprovedComponent } from './approved/approved.component';
import { VerfiedComponent } from './verfied/verfied.component';
import { RejectedComponent } from './rejected/rejected.component';

const routes: Routes = [
  { path: "", redirectTo: "/index", pathMatch: "full" },
  { path: "index", component: IndexComponent },
  { path: "register", component: CRegisterComponent },
  { path: "book", component: BookComponent },
  { path: "home", component: HomeComponent ,canActivate: [AuthGuard], data: { expectedUserGroups: ['AE', 'DE', 'ADMIN'] } },
  { path: "consumer", component: ConsumerComponent },
  { path: "application", component: ApplicationComponent },
  { path: "view", component: ViewComponent },

  { path: "schedule", component: ScheduleComponent,canActivate: [AuthGuard], data: { expectedUserGroups: ['AE', 'DE', 'ADMIN'] }  },
  { path: "approve", component: ProcessingComponent,canActivate: [AuthGuard], data: { expectedUserGroups: ['AE', 'DE', 'ADMIN'] }  },
  { path: "process", component: ChecklistViewComponent,canActivate: [AuthGuard], data: { expectedUserGroups: ['AE', 'DE', 'ADMIN'] }  },


  { path: "approved", component: ApprovedComponent,canActivate: [AuthGuard], data: { expectedUserGroups: ['AE', 'DE', 'ADMIN'] }  },
  { path: "rejected", component: RejectedComponent,canActivate: [AuthGuard], data: { expectedUserGroups: ['AE', 'DE', 'ADMIN'] }  },
  { path: "verfied", component: VerfiedComponent,canActivate: [AuthGuard], data: { expectedUserGroups: ['AE', 'DE', 'ADMIN'] }  },


  { path: "raz-pay-status", component: RazPayStatusComponent },
  // BillDesk
  { path: "payment-status/:tno", component: PaymentStatusComponent },

  // otherwise redirect to main
  { path: "**", redirectTo: "/index" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
