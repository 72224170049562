import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';


@Component({
  selector: 'app-edit-licencee-detail',
  templateUrl: './edit-licencee-detail.component.html',
  styleUrls: ['./edit-licencee-detail.component.scss']
})
export class EditLicenceeDetailComponent implements OnInit {

  @Input()
  app: Applicant;

  @Input()
  formType: any;

  @Input()
  action: string;

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  lic:any[];
  loading = false;
  submitted = false;
  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  this.lic = [{name: 'NEW',value:'1'},{name: 'RENEWAL',value:'2'}];

  // console.log({"app":this.app,"act":this.isAction})
    this.makeFormGroup();

    if(this.isFormType(['F2','F5','F6'])){
      if(this.action == 'VIEW'){
        this.fg.get('licensee_type').disable();
        this.fg.get('licen_no').disable();
      }
    }else if(this.isFormType(['F3'])){
      if(this.action == 'VIEW'){
        this.fg.get('licensee_type').disable();
        this.fg.get('licen_no').disable();
        this.fg.get('licensee_ext_gw_no').disable();
        this.fg.get('islicensee_ext_gw').disable();
      }
    }

    

  }

  private makeFormGroup(){

    if(this.isFormType(['F2','F5','F6'])){
      return this.fg = this.fb.group({
        licensee_type: [this.app.licensee_type, [Validators.required]],
        licen_no: [this.app.licen_no, [Validators.required]],
      })
    }else if(this.isFormType(['F3'])){
    return this.fg = this.fb.group({
      licensee_type: [this.app.licensee_type, [Validators.required]],
      licen_no: [this.app.licen_no, [Validators.required]],
      islicensee_ext_gw: [this.app.islicensee_ext_gw, [Validators.required]],
      licensee_ext_gw_no: [this.app.licensee_ext_gw_no, [Validators.required]],
    })
  }
  }

  isFormType(ut: string | string[]): boolean {
    if (typeof ut === 'string')
      return (this.formType.orgin === ut);
    else {
      const regExp: RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.formType.orgin.match(regExp);
      return match && match.length > 0;
    }
  }

  saveApp() {

    this.submitted = true;

    this.fg.markAsTouched();
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }

}
