import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { DataService } from "../data.service";


@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.component.html',
  styles: []
})
export class ConsumerComponent implements OnInit {
  forms: any;
  user: any;
  fg: FormGroup;
  submitted = false;
  loading = false;
  error_msg: string;

  Applications: any;

  constructor(
    private dataService: DataService,
    private router: Router,
    private fb: FormBuilder,
    ) { }

  ngOnInit() {
    this.user = this.dataService.getCurrentUser();

    this.dataService.getForms({}).subscribe((data) => {
      this.forms = data;
      console.log(this.user);
      if (this.forms && this.user ) {
          this.fg = this.fb.group({
          user_id:[this.user.id],
          form: [null, [Validators.required]],
        });
      }
    });

  
    this.dataService.getApplications({user_id:this.user.id}).subscribe((data) => {
      this.Applications=data;
      console.log(this.Applications);
    });
  }

  edit(e){
    let form = this.forms.filter((res : any) => {
      return e.form_typ === res.id;
    });
    // this.dataService.setCurrentApp({data:{id: e.id,user_id: e.user_id}, formD: form[0], ActionType:'EDIT'});

    localStorage.setItem("fpApplication", JSON.stringify({data:{id: e.id, user_id: e.user_id}, formD: form[0], ActionType:'EDIT'}));
    this.router.navigate(["/application"]);
  }

  view(e){
    let form = this.forms.filter((res : any) => {
      return e.form_typ === res.id;
    });
    localStorage.setItem("fpApplication", JSON.stringify({data:{id: e.id, user_id: e.user_id}, formD: form[0], ActionType:'VIEW'}));
    
    // this.dataService.setCurrentApp({data:{id: e.id,user_id: e.user_id}, formD: form[0], ActionType:'VIEW'});
    this.router.navigate(["/view"]);
  }

  proceed() {
    this.error_msg = null;
    this.submitted = true;
    if (!this.fg.valid) {
      this.error_msg = "All field are required.";
      return;
    }

    const sValue = this.fg.value;
    this.loading = true;
    this.dataService.CreateApplication(sValue).subscribe((data: any) => {
      console.log(data)
      if(data.message == 'Application created successfully.' ){
            if(this.forms && sValue){
            let form = this.forms.filter((res : any) => {
              return sValue.form === res.id;
            });
          // this.dataService.filters.application = {id: data.id, user: data.user_id, formType: form[0], action:'NEW'}; 
          localStorage.setItem("fpApplication", JSON.stringify({data:{id: data.id, user_id: data.user_id}, formD: form[0], ActionType:'NEW'}));
          }
        this.loading = false;
        this.router.navigate(["/application"]);
        return;
      } else {
        this.error_msg = "All field are required.";
        this.fg.setValue(null);
      }
    });
  }

}
