import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Applicant } from '../../../models/app-details';
import { Moment } from 'moment';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styles: []
})
export class OthersComponent implements OnInit {

  @Input()
  app: Applicant;

  @Input()
  proposed: any;

  @Input()
  corps: any;

  @Input()
  formType: any;
  
  @Input()
  action: string;

  @Output()
  saved:EventEmitter<Applicant> = new EventEmitter<Applicant>();

  loading = false;
  submitted = false;
  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  // console.log({"app":this.app,"act":this.isAction})
    this.makeFormGroup();
    
    if(this.isFormType(['F7'])){
      if(this.action == 'VIEW'){
        this.fg.get('date_fr_wr').disable();
        this.fg.get('qty_psd_gw_trn_ltr').disable();
        this.fg.get('crop_type').disable();
        this.fg.get('quanry_wr_utilized').disable();
        this.fg.get('land_extn').disable();
        this.fg.get('purpose_sink_gw').disable();
        this.fg.get('refusal_app_no').disable();
        this.fg.get('date_of_recpt').disable();
        this.fg.get('istrans_well').disable();
        this.fg.get('island_detail_ack').disable();
        this.fg.get('iswater_detail_ack').disable();
      }
  
    }else if(this.isFormType(['F01'])){
      if(this.action == 'VIEW'){
        this.fg.get('date_fr_wr').disable();
        this.fg.get('crop_type').disable();
        this.fg.get('quanry_wr_utilized').disable();
        this.fg.get('purpose_sink_gw').disable();
      }
    }else if(this.isFormType(['F1'])){
      if(this.action == 'VIEW'){
        this.fg.get('purpose_sink_gw').disable();
        this.fg.get('island_detail_ack').disable();
      }
    }else if(this.isFormType(['F2','F3'])){
      if(this.action == 'VIEW'){
        this.fg.get('qty_psd_gw_trn_ltr').disable();
        this.fg.get('purpose_sink_gw').disable();
        this.fg.get('iswater_detail_ack').disable();
      }
    }else if(this.isFormType(['F4','F5'])){
      if(this.action == 'VIEW'){
       
        this.fg.get('purpose_sink_gw').disable();
        
      }
    }else if(this.isFormType(['F6'])){
      if(this.action == 'VIEW'){
        this.fg.get('qty_psd_gw_trn_ltr').disable();
        this.fg.get('purpose_sink_gw').disable();
      }
    }else if(this.isFormType(['F7','F8','F9'])){
      if(this.action == 'VIEW'){
        this.fg.get('date_of_recpt').disable();
      }
    }else if(this.isFormType(['F02'])){
      if(this.action == 'VIEW'){
        this.fg.get('date_fr_wr').disable();
        this.fg.get('crop_type').disable();
        this.fg.get('quanry_wr_utilized').disable();
        this.fg.get('land_extn').disable();
        this.fg.get('istrans_well').disable();
      }
    }

  }

  isFormType(ut: string | string[]): boolean {
    if (typeof ut == 'string')
      return (this.formType.orgin == ut);
    else {
      const regExp: RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.formType.orgin.match(regExp);
      return match && match.length > 0;
    }
  }


  private makeFormGroup(){

    if(this.isFormType(['F7'])){
      return this.fg = this.fb.group({
        date_fr_wr: [this.app.date_fr_wr, [Validators.required]],
        qty_psd_gw_trn_ltr: [this.app.qty_psd_gw_trn_ltr, [Validators.required]],
        crop_type: [this.app.crop_type, [Validators.required]],
        quanry_wr_utilized: [this.app.quanry_wr_utilized, [Validators.required]],
        land_extn: [this.app.land_extn, [Validators.required]],
        purpose_sink_gw: [this.app.purpose_sink_gw, [Validators.required]],
        refusal_app_no: [this.app.refusal_app_no, [Validators.required]],
        date_of_recpt: [this.app.date_of_recpt, [Validators.required]],
        istrans_well: [this.app.istrans_well, [Validators.required]],
        island_detail_ack: [this.app.island_detail_ack, [Validators.required]],
        iswater_detail_ack: [this.app.iswater_detail_ack, [Validators.required]],
      })
    }else if(this.isFormType(['F01'])){
      return this.fg = this.fb.group({
        date_fr_wr: [this.app.date_fr_wr, [Validators.required]],
        crop_type: [this.app.crop_type, [Validators.required]],
        quanry_wr_utilized: [this.app.quanry_wr_utilized, [Validators.required]],
        purpose_sink_gw: [this.app.purpose_sink_gw, [Validators.required]],
      })
    }else if(this.isFormType(['F1'])){
      return this.fg = this.fb.group({
        purpose_sink_gw: [this.app.purpose_sink_gw, [Validators.required]],
        island_detail_ack: [this.app.island_detail_ack, [Validators.required]],
      })
    }else if(this.isFormType(['F2','F3'])){
      return this.fg = this.fb.group({
        purpose_sink_gw: [this.app.purpose_sink_gw, [Validators.required]],
        iswater_detail_ack: [this.app.iswater_detail_ack, [Validators.required]],
        qty_psd_gw_trn_ltr: [this.app.qty_psd_gw_trn_ltr, [Validators.required]],

      })
    }else if(this.isFormType(['F4','F5'])){
      return this.fg = this.fb.group({
        purpose_sink_gw: [this.app.purpose_sink_gw, [Validators.required]],
      })
    }else if(this.isFormType(['F6'])){
      return this.fg = this.fb.group({
        purpose_sink_gw: [this.app.purpose_sink_gw, [Validators.required]],
        qty_psd_gw_trn_ltr: [this.app.qty_psd_gw_trn_ltr, [Validators.required]],

      })
    }else if(this.isFormType(['F7','F8','F9'])){
      return this.fg = this.fb.group({
        date_of_recpt: [this.app.date_of_recpt, [Validators.required]],
      })
    }else if(this.isFormType(['F02'])){
      return this.fg = this.fb.group({
         date_fr_wr: [this.app.date_fr_wr, [Validators.required]],
         crop_type: [this.app.crop_type, [Validators.required]],
         quanry_wr_utilized: [this.app.quanry_wr_utilized, [Validators.required]],
         land_extn: [this.app.land_extn, [Validators.required]],
         istrans_well: [this.app.istrans_well, [Validators.required]],
      })
    }

    
  }

  saveApp() {

    this.submitted = true;

    this.fg.markAsTouched();
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      this.app = Object.assign(sValue);
      this.saved.emit(this.app); // this.update_vStatus();
      this.loading = false;
    }

  }

}
