import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consumer-details',
  templateUrl: './consumer-details.component.html',
  styles: []
})
export class ConsumerDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
