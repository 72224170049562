import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import * as moment from "moment";

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styles: []
})
export class ChecklistComponent implements OnInit {
  loading = false;
  submitted = false;
  fg: FormGroup;
  error_msg:string;
  data:any;
  user:any;

  constructor(private fb: FormBuilder,private dataService: DataService,
    private router: Router,) { }

  ngOnInit() {

    this.data = JSON.parse(localStorage.getItem("fpApplication"));
    this.user = this.dataService.getCurrentUser();

    this.fg = this.fb.group({
          owner_land_proposed: [null, [Validators.required]],
          owner_land_enclosed: [null, [Validators.required]],
          existing_lic_validity: [null, [Validators.required]],
          exist_well: [null, [Validators.required]],
          owner_water_enclosed: [null, [Validators.required]],
          lic_obtain_extraction: [null, [Validators.required]],
          all_address_prof: [null, [Validators.required]],
          all_doc_correct: [null, [Validators.required]],
          remarks: [null, [Validators.required]],
    });
  }

  Verify() {

    this.error_msg = null;
    this.submitted = true;

    console.log(this.fg);

    if (!this.fg.valid) {
      this.error_msg = "All fields are mandatory.";
      return;
    }
    // console.log("Schedule Details");

    this.fg.markAsTouched();
    console.log(this.fg.value);
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      Object.assign(sValue, {app_id:this.data.data.id, user_id: this.user.id, status: 8, screen: 'VEFY'})
      console.log(sValue);
      this.dataService.saveCheckList(sValue).subscribe((res :any) => {
        if(res.message == 'Application Checked successfully.' ){
          this.dataService.saveSchedule(sValue).subscribe((res: any) => {
            if(res.message == 'Application scheduled successfully.' ){
              this.loading = false;
              this.router.navigate(["/process"]);
              return;
            }
          });
      } else {
      this.error_msg = "All field are required.";
      this.fg.setValue(null);
      }
      });
     
    }

  }

  reject() {

    this.error_msg = null;
    this.submitted = true;

    console.log(this.fg);

    if (!this.fg.valid) {
      this.error_msg = "All fields are mandatory.";
      return;
    }
    // console.log("Schedule Details");

    this.fg.markAsTouched();
    console.log(this.fg.value);
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      Object.assign(sValue, {app_id:this.data.data.id, user_id: this.user.id, status: 3, screen: 'REJT'})
      console.log(sValue);
      this.dataService.saveCheckList(sValue).subscribe((res :any) => {
        if(res.message == 'Application Checked successfully.' ){
          this.dataService.saveSchedule(sValue).subscribe((res: any) => {
            if(res.message == 'Application scheduled successfully.' ){
              this.loading = false;
              this.router.navigate(["/process"]);
              return;
            }
          });
      } else {
      this.error_msg = "All field are required.";
      this.fg.setValue(null);
      }
      });
     
    }

  }

  request() {

    this.error_msg = null;
    this.submitted = true;

    console.log(this.fg);

    if (!this.fg.valid) {
      this.error_msg = "All fields are mandatory.";
      return;
    }
    // console.log("Schedule Details");

    this.fg.markAsTouched();
    console.log(this.fg.value);
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      Object.assign(sValue, {app_id:this.data.data.id, user_id: this.user.id, status: 5, screen: 'RC'})
      console.log(sValue);
      this.dataService.saveCheckList(sValue).subscribe((res :any) => {
        if(res.message == 'Application Checked successfully.' ){
          this.dataService.saveSchedule(sValue).subscribe((res: any) => {
            if(res.message == 'Application scheduled successfully.' ){
              this.loading = false;
              this.router.navigate(["/process"]);
              return;
            }
          });
      } else {
      this.error_msg = "All field are required.";
      this.fg.setValue(null);
      }
      });
     
    }

  }

}
