import { SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "http://gwra.broadline-beta.com/svr/",
  // apiUrl: "../svr/",

  makePaymentReturnURL(d: DomSanitizer) {
    const href = this.apiUrl + "payment-status.php";
    const return_url = d.sanitize(SecurityContext.URL, href);
    return return_url;
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
