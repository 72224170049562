import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

import { User } from '../models/signup';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styles: []
})
export class ViewComponent implements OnInit {
  user: User;
  application: any;
  form_type: any;
  Action: any;
  users: any;

  log: any

  screen: any;

  constructor(private dataService: DataService,
    private router: Router,) { }

  ngOnInit() {
    this.users = this.dataService.getCurrentUser();
    let data = JSON.parse(localStorage.getItem("fpApplication"));
    this.screen = data.screen;
    this.application = data.data;
    this.form_type = data.formD;
    this.Action = data.ActionType;
    this.dataService.getApplicationLog(this.application).subscribe((res) => {
      this.log = res;
    });
  }

  isUsrType(ut: string | string[]): boolean {
    if (typeof ut == "string") return this.users.group_cd == ut;
    else {
      const regExp: RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.users.group_cd.match(regExp);
      return match && match.length > 0;
    }
  }

}
