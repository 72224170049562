import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { DataService } from '../../data.service';
import { Consumer } from '../../models';

@Component({
  templateUrl: './otp-verification.component.html',
  styles: []
})
export class OTPVerificationDialogComponent implements OnInit {
  submitted = false;
  loading = false;
  fg: FormGroup;
  error_msg: String;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) private consumer: Consumer,
    private dialogRef: MatDialogRef<OTPVerificationDialogComponent>
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      otp: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  validateOTP() {
    this.error_msg = null;
    this.submitted = true;

    if (this.fg.valid) {
      let sValue = this.fg.value;
      const consumer = Object.assign({ }, this.consumer);
      // consumer.via = 'i';
      consumer.otp = sValue.otp;

      this.loading = true;
      this.dataService.validateOTP(consumer).subscribe((data: any) => {
        this.loading = false;
        if (data.message === 'Invalid OTP.') {
          this.error_msg = data.message;
          setTimeout(() => {
            this.dialogRef.close(false);
          }, 5000);
          return;
        }

        if (data.booking) {
          this.dataService.setBook(data.booking);
        }
        this.dialogRef.close(true);
      });
    }
  };
  
  cancel() {
    this.dialogRef.close(false);
  }
}
