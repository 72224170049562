import { Injectable } from '@angular/core';
import { HttpEvent, HttpHeaders, HttpInterceptor, HTTP_INTERCEPTORS, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


import { ErrorMessageDialogComponent } from '../dialogs/error-message/error-message.component';

@Injectable()
export class AppResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    public dialog: MatDialog
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // next.handle(req).forEach()
    let ret = next.handle(req)
      .pipe(
        map((event: HttpEvent<any>) => {

          if (event instanceof HttpResponse) {
            // console.log(event);
            if (event.headers)
            {
              let headers: HttpHeaders = event.headers;
              if (headers.get('x-token'))
              {
                let newToken = JSON.stringify(headers.get('x-token'));
                localStorage.setItem("authorizationData", newToken);
              }
            }
          }
          return event;
        }),
        catchError(err => {
          console.log(err);
          if (err instanceof HttpErrorResponse)
          {
            if (err.status === 401)
            {
              // let message = "Unknown Server Error. Please Login again.";
              
              // if (typeof err.error === "string")
              //   message = err.error;

              // alert('Session Expired.');
              let error_message_data: { 
                title: string, 
                error_message: string, 
                color?: 'red'|'darkgreen', 
                return?: boolean } = {
                  title: 'Session Expired',
                  error_message: 'Your session has expired. Please refresh and try again',
                  color: 'red',
                  return: true
                };
              let config: MatDialogConfig = {
                width: '550px',
                disableClose: true,
                hasBackdrop: true,
                data: error_message_data
              };
              const dialogRef = this.dialog.open(ErrorMessageDialogComponent, config);
              dialogRef.afterClosed().subscribe((result?: any) => {
                if (result) {
                  this.router.navigate(['/index'], { skipLocationChange: true });
                }
              });

              // JWT expired, go to login
              return throwError(err); // Observable.throw(err);
            }
    
            if (err.status < 200 || err.status > 299)
            {
              console.log("Server Responded Error");
              return throwError(err); // Observable.throw(err);
            }
          }

          return throwError(err); // Observable.throw(err);
        })
    );

    return ret;
  }
}

export const appResponseInterceptorProvider = { 
  provide: HTTP_INTERCEPTORS,
  useClass: AppResponseInterceptor,
  multi: true
};
