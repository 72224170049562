import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import * as moment from "moment";


@Component({
  selector: 'app-schedule-app',
  templateUrl: './schedule-app.component.html',
  styles: []
})
export class ScheduleAppComponent implements OnInit {

  loading = false;
  submitted = false;
  fg: FormGroup;
  error_msg:string;
  data:any;
  user:any;

  tstatus: number;
  title:string;
  dateinput:string;

  constructor(private fb: FormBuilder,private dataService: DataService,
    private router: Router,) { }

  ngOnInit() {

    this.data = JSON.parse(localStorage.getItem("fpApplication"));
    this.user = this.dataService.getCurrentUser();
    console.log(this.data)
    if(this.data.screen === 'SCHD'){
      this.title = "Schedule";
      this.dateinput = "Schedule";
      this.tstatus = 4;
    }

    if(this.data.screen == 'RESD'){
      this.title = "Reschedule";
      this.tstatus = 4;

      this.dateinput = "Reschedule";
    }

    if(this.data.screen == 'SANT'){
      this.tstatus = 9;
      this.title = "Sanctioned";
      this.dateinput = "Sanctioned";
    }

    this.fg = this.fb.group({
      schedule_date: [null, [Validators.required]],
      remarks: [null, [Validators.required]],
    });
  }

  Schedule() {

    this.error_msg = null;
    this.submitted = true;

    if (!this.fg.valid) {
      this.error_msg = "All fields are mandatory.";
      return;
    }
    console.log("Schedule Details");

    this.fg.markAsTouched();
    console.log(this.fg.value);
    if (this.fg.valid) {
      this.loading = true;
      const sValue = this.fg.value;
      Object.assign(sValue, {app_id:this.data.data.id, user_id: this.user.id,status: this.tstatus, screen: this.data.screen})
      this.dataService.saveSchedule(sValue).subscribe((res :any) => {
        if(res.message == 'Application scheduled successfully.' ){
          
          this.loading = false;
          this.router.navigate(["/schedule"]);
      return;
      } else {
      this.error_msg = "All field are required.";
      this.fg.setValue(null);
      }
      });
      // console.log(sValue);
     
    }

  }


}
