import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServerTimeService {
  serverTime = new Subject<Date>();

  updateNewTime(dt: Date) {
      this.serverTime.next(dt);
  }
}
