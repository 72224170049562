import { Component, Input } from '@angular/core';

import { Consumer } from '../models/consumer';

@Component({
  selector: 'view-consumer',
  templateUrl: './view-consumer.component.html'
})
export class ViewConsumerComponent {
  @Input()
  consumer: Consumer;
}
