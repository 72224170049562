import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import * as moment from "moment";

import { DataService } from "../data.service";
import { ServerTimeService } from "../server-time/server-time.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  // serverTime: Date;
  user: any;
  showMenu = false;

  constructor(
    private dataService: DataService,
    private serverTimeService: ServerTimeService,
    private router: Router
  ) {}

  ngOnInit() {
    this.dataService.getServerTime().subscribe((res) => {
      this.serverTimeService.updateNewTime(moment(res.a).toDate());
      // this.serverTime = ;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          this.router.url.startsWith("/register") ||
          this.router.url.startsWith("/index")
        ) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
          this.user = this.dataService.getCurrentUser();
          // console.log(this.user);
        }
      }
    });
  }

  isUsrType(ut: string | string[]): boolean {
    if (typeof ut == "string") return this.user.group_cd == ut;
    else {
      const regExp: RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.user.group_cd.match(regExp);
      return match && match.length > 0;
    }
  }

  logout() {
    this.dataService.logout();
    this.router.navigate(["/index"]);
  }
}
