import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

import { User } from '../models/signup';
@Component({
  selector: 'app-verfied',
  templateUrl: './verfied.component.html',
  styles: []
})
export class VerfiedComponent implements OnInit {

  user: any;
  applications: any;
  forms: any;
  Action: any;

  data: any

  constructor(private dataService: DataService,
    private router: Router,) { }

  ngOnInit() {
    this.user = this.dataService.getCurrentUser();
    // let data = JSON.parse(localStorage.getItem("fpApplication"));
    // this.applications = data.data;
    // this.form_type = data.formD;
    // this.Action = data.ActionType;
    this.dataService.getForms({}).subscribe((data) => {
      this.forms = data;
    });

    if(this.user.group_cd === 'DE'){
      this.data = {division: this.user.depot_id,status:8,submitted:true};
    }

    if(this.user.group_cd === 'AE'){
      this.data = {area: this.user.area_id,status:8,submitted:true};
    }

    if(this.user){
      this.dataService.getAllApplication(this.data).subscribe((res) => {
        this.applications = res;
        console.log(this.applications);
      });
    }
  }

  view(e){

    let form = this.forms.filter((res : any) => {
      return e.form_name === res.form_name;
    });
    
    console.log(form);
    localStorage.setItem("fpApplication", JSON.stringify({data:{id: e.id, user_id: e.user_id}, formD: form[0], ActionType:'VIEW',screen:'VERY'}));
    // this.dataService.setCurrentApp({data:{id: e.id,user_id: e.user_id}, formD: form[0], ActionType:'VIEW'});
    this.router.navigate(["/view"]);
  }
}

