import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';

import { DataService } from '../data.service';
import { ErrorMessageDialogComponent } from '../dialogs/error-message/error-message.component';

@Component({
  selector: 'update-delivered',
  templateUrl: './update-delivered.component.html'
})
export class UpdateDeliveredComponent implements OnInit {
  fg: FormGroup;
  submitted = false;
  loading = false;
  error_msg: string;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fg = this.fb.group({
      mobile_no: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      pin: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });
  }

  confirmDelivery() {
    this.error_msg = null;
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (!this.fg.valid)
    {
      this.error_msg = 'All fields are mandatory.';
      return;
    }

    const sValue = this.fg.value;
    this.loading = true;
    this.dataService.confirmDeliveryByConsumer(sValue).subscribe((data: any) => {
      this.loading = false;
      let message_data: { title: string, error_message: string, color?: 'red'|'darkgreen', return?: boolean };
      if (data.message === 'Booking status updated Successfully.') {
        message_data = {
          error_message: data.message,
          title: 'Dial for water',
          color: 'darkgreen'
        }
        this.reset()
      } else {
        message_data = {
          error_message: data.message,
          title: 'Dial for water'
        }
      }

      this.showMessage(message_data);
    });
  }


  private showMessage(message_data: { title: string; error_message: string; color?: "red" | "darkgreen"; return?: boolean; }) {
    if (message_data) {
      if (!message_data.color)
        message_data.color = 'red';
      if (!message_data.hasOwnProperty('return'))
        message_data.return = true;
      let config: MatDialogConfig = {
        width: '550px',
        disableClose: true,
        hasBackdrop: true,
        data: message_data
      };
      this.dialog.open(ErrorMessageDialogComponent, config);
    }
  }

  reset() {
    this.submitted = false;
    this.fg.get('mobile_no').setValue(null);
    this.fg.get('pin').setValue(null);
    this.fg.markAsPristine();
    this.fg.markAsUntouched();
  }
}
