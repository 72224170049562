import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  templateUrl: './error-message.component.html'
})
export class ErrorMessageDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public msg: { error_message: string, title: string, color?: 'red'|'darkgreen', return?: boolean },
    private dialogRef: MatDialogRef<ErrorMessageDialogComponent>
  ) { }
  
  cancel() {
    this.dialogRef.close(this.msg.return);
  }
}
