import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { MatDialogConfig, MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { of } from "rxjs";

import { environment } from "../environments/environment";
import {
  Consumer,
  ConsumerSearchType,
  Booking,
  LastBooking,
  Payment,
  CurrentApp
} from "./models";
import { ErrorMessageDialogComponent } from "./dialogs/error-message/error-message.component";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private currentConsumer: Consumer;
  private CurrentApp: CurrentApp
  private currentUser: any;
  private currentMobileno: any;
  private currentBook: Booking;
  private currentPayment: Payment;
  private headers = new HttpHeaders()
    .set("Content-Type", "application/json")
    .set("Accept", "application/json");
  // reload: Subject<boolean> = new Subject();


  //public 

  public filters:{
    application?: CurrentApp,
    apply?: any
  } = {};

  constructor(
    private router: Router,
    private http: HttpClient,
    public dialog: MatDialog
  ) {}

  setCurrentConsumer(c: any) {
    // localStorage.setItem("fpUser", JSON.stringify(c));
    this.currentConsumer = c;

  }

  setCurrentApp(c: CurrentApp) {
    // localStorage.setItem("fpUser", JSON.stringify(c));
    this.CurrentApp = c;

  }

  setMobileno(c: any) {
    // localStorage.setItem("Mobileno", JSON.stringify(c));
    this.currentMobileno = c;
  }

  getCurrentConsumer(): any {
    if (this.currentConsumer) {
      return Object.assign({}, this.currentConsumer);
    } else {
      this.router.navigate(["/index"], { skipLocationChange: true });
      return;
    }
  }

  getCurrentApp(): CurrentApp {
    if (this.CurrentApp) {
      return Object.assign({}, this.CurrentApp);
    } else {
      this.router.navigate(["/index"]);
      return;
    }
  }

  getCurrentMobileno(): Consumer {
    if (this.currentMobileno) {
      return Object.assign({}, this.currentMobileno);
    } else {
      this.router.navigate(["/index"], { skipLocationChange: true });
      return;
    }
  }

  getCurrentUser(): any {
    if (this.currentUser) {
      return this.currentUser;
    } else if (localStorage.getItem("fpUser")) {
      let data = JSON.parse(localStorage.getItem("fpUser"));
      this.currentUser = data;
      return this.currentUser;
    } else {
      this.router.navigate(["/login"]);
      return null;
    }
  }

  logout() {
    // remove user from local storage to log user out
    this.currentUser = null;
    localStorage.removeItem("fpUser");
    localStorage.removeItem("authorizationData");
    localStorage.removeItem("fpApplication");

  }

  setBook(b: Booking) {
    this.currentBook = b;
  }

  getBook(): Booking {
    if (this.currentBook) {
      return Object.assign({}, this.currentBook);
    } else {
      this.router.navigate(["/index"], { skipLocationChange: true });
      return;
    }
  }

  setPayment(p: Payment) {
    this.currentPayment = p;
  }

  getPayment(): Payment {
    if (this.currentPayment) {
      return Object.assign({}, this.currentPayment);
    } else {
      this.router.navigate(["/index"], { skipLocationChange: true });
      return;
    }
  }

  getServerTime() {
    return this.http.get<{ a: Date }>(environment.apiUrl + "dfw.php", {
      headers: this.headers,
      params: new HttpParams().set("getServerTime", "true"),
    });
  }

  searchConsumer(data: ConsumerSearchType) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("searchConsumer", "true"),
    });
  }

  login(data: ConsumerSearchType) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("login", "true"),
    });
  }

  parseConsumerData(data: any) {
    let error_message_data: {
      title: string;
      error_message: string;
      color?: "red" | "darkgreen";
      return?: boolean;
    };

    if (data.message === "Web Service not accessible.") {
      error_message_data = {
        error_message:
          "CMWSSB / Corp. Server unavailable to validate your CMC No. Please try again after sometime.",
        title: "Server unavailable",
      };
    }

    if (data.message === "No Customer Found.") {
      error_message_data = {
        error_message:
          "Property cannot be located, check your CMC No. and search again.",
        title: "Property not found",
      };
    }

    if (data.message === "Not a Registered Mobile No.") {
      error_message_data = {
        error_message:
          "Mobile No. is not registered for dial for water service. Please check your mobile number.",
        title: "Mobile Number not registered",
      };
    }

    if (data.message === "Next OTP will be send after 30 seconds.") {
      error_message_data = {
        error_message: "Next OTP will be send after 30 seconds.",
        title: "",
      };
    }

    if (data.message === "Consumer Blocked.") {
      error_message_data = {
        error_message: "Reason: " + data.reason,
        title: "Consumer Blocked",
      };
    }

    if (data.message === "Commercial Consumers not allowed.") {
      error_message_data = {
        error_message:
          "Property categorised as commercial are not allowed for Dial for water service.",
        title: "Commercial Property",
      };
    }

    if (data.message === "Arrears Found.") {
      error_message_data = {
        error_message:
          "Please pay arrears due amount (₹ " +
          (data.arrear_amount as number).toLocaleString("hi-IN", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          ") to avail dial for water service.",
        title: "Arrears Due",
      };
    }

    if (
      data.consumer &&
      data.consumer.is_registered &&
      !data.consumer.is_search_by_mobile
    ) {
      error_message_data = {
        error_message:
          "You have already registered. To book a water tanker use your registered mobile number.",
        title: "Already registered",
        color: "darkgreen",
      };
    }

    if (
      data.consumer &&
      !data.consumer.is_registered &&
      data.consumer.must_re_register
    ) {
      error_message_data = {
        error_message:
          "You have already registered, but we require additional / missing information. You will be taken to registration screen to re-register.",
        title: "Additional / Missing Information",
        return: false,
      };
    }

    if (error_message_data) {
      if (!error_message_data.color) error_message_data.color = "red";
      if (!error_message_data.hasOwnProperty("return"))
        error_message_data.return = true;

      let config: MatDialogConfig = {
        width: "550px",
        disableClose: true,
        hasBackdrop: true,
        data: error_message_data,
      };
      const dialogRef = this.dialog.open(ErrorMessageDialogComponent, config);
      return dialogRef.afterClosed();
    } else {
      return of(false);
    }
  }

  saveConsumer(data: Consumer) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("saveConsumer", "true"),
    });
  }

  

  checkMobileNoAndGenerateOTP(data: {
    mobile_no: string;
    zone_no?: string;
    ward_no?: string;
    bill_no?: string;
    sub_code?: string;
  }) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("checkMobileNoAndGenerateOTP", "true"),
    });
  }

  generateOTP(data: Consumer) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("generateOTP", "true"),
    });
  }

  validateOTP(data: Consumer) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("validateOTP", "true"),
    });
  }

  // getDFWConsumer(data: ConsumerSearchType) {
  //   return this.http.post(
  //     environment.apiUrl + 'dfw.php',
  //     data,
  //     { headers: this.headers, params: new HttpParams().set('getDFWConsumer', 'true') }
  //   );
  // }

  // searchConsumerForBooking(data: ConsumerSearchType) {
  //   return this.http.post(
  //     environment.apiUrl + 'dfw.php',
  //     data,
  //     { headers: this.headers, params: new HttpParams().set('searchConsumerForBooking', 'true') }
  //   );
  // }

  getBooking(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getBooking", "true"),
    });
  }

  saveBook(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("saveBook", "true"),
    });
  }

  getBookingStatus(data: { mobile_no: string; booking_no: string }) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getBookingStatus", "true"),
    });
  }

  makePayment(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("makePayment", "true"),
    });
  }

  cancelledPayment(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("cancelledPayment", "true"),
    });
  }

  capturePayment(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("capturePayment", "true"),
    });
  }

  getPaymentStatus(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getPaymentStatus", "true"),
    });
  }

  makePaymentStatusPdf(data: any) {
    let cusHeaders = this.headers.append("Accept", "application/pdf");
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: cusHeaders,
      params: new HttpParams().set("makePaymentStatusPdf", "true"),
      responseType: "blob",
    });
  }

  confirmDeliveryByConsumer(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("confirmDeliveryByConsumer", "true"),
    });
  }

  resendPIN(data: LastBooking) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("resendPIN", "true"),
    });
  }

  // New apis

  CreateApplication(data: any){
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("CreateApplication", "true"),
    });
  }

  getApplicationDetail(data: any){
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getApplicationDetail", "true"),
    });
  }

  getForms(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getForms", "true"),
    });
  }

  getZones(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getZones", "true"),
    });
  }

  getAreaTypes(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getAreaTypes", "true"),
    });
  }

  getDepots(data: any) {
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getDepots", "true"),
    });
  }

  getPumps(data: any){
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getPumps", "true"),
    });
  }

  getWells(data: any){
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getWells", "true"),
    });
  }

  getProposed(data: any){
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getProposed", "true"),
    });
  }

  getCrops(data: any){
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getCrops", "true"),
    });
  }

  getTransport(data: any){
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getTransport", "true"),
    });
  }

  getDecl(data: any){
    return this.http.post(environment.apiUrl + "dfw.php", data, {
      headers: this.headers,
      params: new HttpParams().set("getDecl", "true"),
    });
  }

  saveApp(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveApp', 'true') }
    );
  }

  saveOwn(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveOwn', 'true') }
    );
  }

  saveOccup(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveOccup', 'true') }
    );
  }

  saveGeneral(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveGeneral', 'true') }
    );
  }

  saveLocation(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveLocation', 'true') }
    );
  }

  saveOthers(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveOthers', 'true') }
    );
  }

  saveWells(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveWells', 'true') }
    );
  }

  savelicensee(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('savelicensee', 'true') }
    );
  }

  savePump(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('savePump', 'true') }
    );
  }

  saveRefusal(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveRefusal', 'true') }
    );
  }

  saveTransport(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveTransport', 'true') }
    );
  }

  getApplications(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('getApplications', 'true') }
    );
  }

  saveSubmit(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveSubmit', 'true') }
    );
  }

  getApplicationLog(data: any) {
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('getApplicationLog', 'true') }
    );
  }

  getAllApplication(data: any){
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('getAllApplication', 'true') }
    );
  }

  saveSchedule(data: any){
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveSchedule', 'true') }
    );
  }

  saveCheckList(data: any){
    return this.http.post(
      environment.apiUrl + 'dfw.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveCheckList', 'true') }
    );
  }
  // formChange(data: number){

  //   switch(data){
  //     case 1:['APP','OWN','WELL','']
  //   }

  // }
}
